<template>
  <div class="tabtop-warp my">
    <div class="tabtop-warp-list">
      <draggable
        :list="panellist"
        :disabled="!enabled"
        class="list-group"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="moveend"
      >
        <div
          class="list-group-item"
          :class="{ 'active-item': element.id == allwidget.id }"
          v-for="element in panellist"
          :key="element.name"
          @click.stop="clickpanel(element)"
        >
          <i class="lowcode icon-tuozhuai"></i>
          <font>{{ element.name }}</font>
        </div>
      </draggable>
    </div>
    <div class="tabtop-warp-operate" >
      <a-dropdown :trigger="['click']">
        <span class="operate-add-btn">
          <!-- <i class="lowcode icon-plus"></i> -->
          <img
            src="@/assets/imgs/echarts/abb_edit_32.svg"
            alt=""
            style="width: 16px; height: 16px;position: absolute; top:7px"
          />
          <font style="margin-left: 20px;">{{LangHandler('dragedit','编辑')}}</font>
        </span>
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="addPanel">
            <!-- <i class="lowcode icon-zengjiahang"></i> 添加驾驶舱 -->
            <img
              src="@/assets/imgs/echarts/abb_object-container-2_32.svg"
              alt=""
              style="width: 16px; height: 16px; position: absolute; top: 20px"
            />
            <span style="margin-left: 20px">{{LangHandler('dragaddcockpit','添加驾驶舱')}}</span>
          </a-menu-item>
          <a-menu-item key="addBoard">
            <!-- <i class="lowcode icon-mokuai"></i> 添加组件 -->
            <img
              src="@/assets/imgs/echarts/abb_thumbnail-view_32.svg"
              alt=""
              style="width: 16px; height: 16px; position: absolute; top: 60px"
            />
            <span style="margin-left: 20px">{{LangHandler('dragaddmodule','添加组件')}}</span>
          </a-menu-item>
          <a-menu-item key="delPanel">
            <!-- <i class="lowcode icon-shanchu3"></i> 删除驾驶舱 -->
            <img
              src="@/assets/imgs/echarts/abb_trash_32.svg"
              alt=""
              style="width: 16px; height: 16px; position: absolute; top: 100px"
            />
            <span style="margin-left: 20px">{{LangHandler('dragdelete','删除驾驶舱')}}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <!-- <span class="operate-more-btn">
                <i class="lowcode icon-gengduo-act"></i>
            </span> -->
    </div>

    <add1 :show="showadd1" />
    <add2 ref="setPanel" />
    <add3 ref="showadd3" />
    <chartStore ref="chartStore" />
  </div>
</template>
  
  <script>
import draggable from "vuedraggable";
import chartStore from "@/component/chartStore/chartStore.vue";

import add1 from "@/component/add1.vue";
import add2 from "@/component/add2.vue";
import add3 from "@/component/add3.vue";
export default {
  name: "tabtop",
  components: {
    draggable,
    chartStore,
    add1,
    add2,
    add3,
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      showadd3: false,
      showadd1: false,
    };
  },
  props: {
    panellist: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    // 当前选择菜单信息
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    allwidget() {
      return this.$store.state.allwidget;
    },
    Menu(){
      for(let item of this.$store.state.allMenuData){
        if(item.name == 'widget管理'){
          return true
        }
      }
      return false
    }
    
  },
  methods: {
    
    handleMenuClick(e) {
      var event = e.key;
      this[event]();
     
    },
    // 添加组件
    addBoard() {
      if (!this.currentSelectMenuInfo.id) {
        this.$message.warning("请选择菜单！");
        return;
      }
      if (!this.currentSelectDeptInfo.CODE) {
        this.$message.warning("请选择机构！");
        return;
      }
      if (!this.allwidget.id) {
        this.$message.warning("请添加驾驶舱！");
        return;
      }
      
      this.$refs.chartStore.open();
    },
    // 添加驾驶舱
    addPanel() {
    
      if (this.currentSelectMenuInfo.id) {
         
        this.$refs.setPanel.open();
      } else {
        this.$message.warning("请选择菜单！");
      }
    },
    // 删除驾驶舱
    delPanel() {
      if (this.currentSelectMenuInfo.id) {
        this.$refs.showadd3.open();
      } else {
        this.$message.warning("当前无驾驶舱");
      }
      // if (!this.allwidget.id) {
      //   this.$message.warning("当前无驾驶舱");
      //   return;
      // }
      // let vm = this;
      // this.$confirm({
      //   title: "确定删除当前驾驶舱",
      //   okText: "确认",
      //   cancelText: "取消",
      //   onOk() {
      //     var url =
      //       Config.dev_url +
      //       "/api-apps/panel/dele?id=" +
      //       vm.$store.state.allwidget.id;
      //     vm.$axios
      //       .get(url, {})
      //       .then((res) => {
      //         if (res.data.code == 0) {
      //           vm.$message({
      //             type: "success",
      //             message: "删除成功!",
      //           });
      //           vm.$store.commit("setallwidget", {});
      //           vm.$store.commit("setisRequestPanelInfo", true);
      //         } else {
      //           vm.$message({
      //             type: "warning",
      //             message: res.data.msg,
      //           });
      //         }
      //       })
      //       .catch((err) => {});
      //   },
      // });
    },
    // 选中
    clickpanel(item) {
      item.url = this.$route.path;
      
      this.$store.commit("setallwidget", item);
    },

    //   add: function() {
    //     this.list.push({ name: "Juan " + id, id: id++ });
    //   },
    //   replace: function() {
    //     this.list = [{ name: "Edgard", id: id++ }];
    //   },
    moveend: function (e) {
      this.$nextTick(function () { 
      
        let arr = JSON.parse(JSON.stringify(this.panellist))
            console.log(arr)
            for(let index in this.panellist){
              
              // if(index == 0){

              // }else{
                arr[index].index = index
                
              // }
            }
          // arr.shift()
          let arr2 =[]
          for(let item of arr){
            arr2.push({
              id:item.id,
              index:item.index,
            })
          }
          const url = Config.dev_url + "/api-apps/panel/modify";
          var data = arr2
          this.$axios.post(url,data ).then((res) => {
             
          })
          .catch((err) => {}).finally(()=>{

          })    
       
      })
      
      
      
      
    },
    checkMove: function (e) {
      // this.$nextTick(function () { 
      //   let arr = JSON.parse(JSON.stringify(e.relatedContext.list))
        
        
        
      //   setTimeout(() => {
      //       console.log(arr)
      //       for(let index in e.relatedContext.list){
              
      //         // if(index == 0){

      //         // }else{
      //         //   e.relatedContext.list[index].index = index
      //         //   console.log(e.relatedContext.list[index])
      //         // }
      //       }
      //       console.log(e.relatedContext.list)
      //   }, 100);
      // })
      
      
      
      
    },
  },
};
</script>
<style scoped lang="less">
.tabtop-warp {
  margin-left: 9px;
  display: flex;
  .tabtop-warp-list {
    flex: 1;
    margin-top: 16px;
    .list-group {
      width: 100%;
      height: 40px;
      .list-group-item {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        float: left;
        margin-right: 8px;
        height: 40px;
        padding: 8px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        // box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
        font-size: 16px;
        font-weight: 400;
        color: #1f1f1f;
        line-height: 24px;

        i {
          margin-right: 6px;
          color: #8f959e;
        }
        &:hover {
          font-weight: 500;
          color: #205de8;
          text-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
          cursor: pointer;
          i {
            color: #205de8;
          }
        }
        &:active {
          background: #dfe7fc;
          border-radius: 4px;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

          font-size: 16px;
          font-weight: 500;
          color: #205de8;
          text-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
          cursor: move;
          i {
            color: #205de8;
          }
        }
      }
      .active-item {
        background: #f5f6f7;
        border-radius: 4px;
        // box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
        font-size: 16px;
        font-weight: 500;
        color: #205de8;
        text-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
    }
  }
  .tabtop-warp-operate {
    // margin-right: 16px;
    margin-right: 6px;
    margin-top: 20px;
    .operate-add-btn {
      position: relative;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
      height: 32px;
      display: inline-block;
      box-sizing: border-box;
      background: #00338e;
      border-radius: 4px;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      padding: 6px 16px 6px 14px;

      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      text-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      i {
        margin-right: 10px;
      }
      margin-right: 12px;
      &:hover {
        background: #5d8aff;
      }
      &:active {
        background: #2347d9;
      }
    }
    .operate-more-btn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      font-weight: 900;
      &:hover {
        border-radius: 2px;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>