<template>
  <div class="dele">
    <div class="custom-dialog">
      <el-dialog
        :title="LangHandler('deletecockpit','删除驾驶舱')"
        :visible.sync="dialogVisible"
        width="30%"
        :modal="true"
      >
        <div style="width: 100%;height: 30px;line-height: 30px;margin-left: 9px;font-weight: 600;color: #3d3b3b;">
          <i class="el-icon-warning" style="color: rgb(230, 162, 60);font-size: 24px!important;position: relative;top: 4px;margin-right: 8px;"></i>
          <span style="color: #3d3b3b;"> {{LangHandler('confirmdelete','确定删除当前驾驶舱')}} </span>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
          style="display: flex; justify-content: center"
        >
          <el-button size="mini" @click="dialogVisible = false"
            >{{LangHandler('cancle','取 消')}}</el-button
          >
          <el-button
            size="mini"
            style="background: #3366ff"
            type="primary"
            @click="dele"
            >{{LangHandler('confirm','确 定')}}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "dele",
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    // 当前菜单
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    allwidget() {
      return this.$store.state.allwidget;
    },
  },
  watch: {},
  methods: {
    open() {
      this.dialogVisible = true;
      // console.log(this.delPaneldata);
    },
    dele() {
      if(this.$store.state.allwidget.location == "old"){
        this.$message({
          type: "success",
          message: "默认总览无法删除",
        });
        return
      }
      console.log(this.$store.state.allwidget)
      // console.log(vm.$store.state.allwidget.id);
      let vm = this;
      const url =
        Config.dev_url +
        "/api-apps/panel/dele?id=" + vm.$store.state.allwidget.id;
      this.$axios
        .get(url, {})
        .then((res) => {
          if (res.data.code == 0) {
            vm.$message({
              type: "success",
              message: "删除成功!",
            });
            vm.$store.commit("setallwidget", {});
            vm.$store.commit("setisRequestPanelInfo", true);
          } else {
            vm.$message({
              type: "warning",
              message: res.data.msg,
            });
          }
          this.dialogVisible = false
        })
        .catch((err) => {});
    },
  },
  mounted() {
    console.log(this.$store.state.allwidget.id);
  },
};
</script>

<style lang='less'>
.dele{
  .el-dialog{
    margin-top: 40vh !important;
  }
  .el-dialog__body{
    padding: 40px 12px;
  }
}
</style>
