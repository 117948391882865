<template>
  <div class="abb-canvas">
    <div class="abb-canvas-aside" v-show="menuAndDrawer">
      <div class="abb-aside-search">
        <a-input-search :placeholder="LangHandler('powersourcesearch','请输入搜索的机构名称')" v-model="searchValue" @search="onSearch" />
        <span class="shousuo" @click="handlerisCollapse">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{LangHandler('powersourcemenu','菜单收起')}}</span>
            </template>
            <!-- <i class="lowcode icon-zhuandao1"></i> -->
            <img src="@/assets/imgs/echarts/abb_left_32.svg" alt="">
          </a-tooltip>

        </span>
      </div>
      <!-- :load-data="onLoadData" -->
      <a-tree class="deptTree" :tree-data="filterDeptData" v-if="filterDeptData.length > 0" default-expand-all show-icon
        :default-selected-keys="defaultSelectedKeys" :replaceFields="replaceFields" @select="onSelect">
        <i slot="switcherIcon" class="lowcode icon-right"></i>
        <i slot="headquarters" class="lowcode icon-fangwu"></i>
      </a-tree>
    </div>
    <div class="abb-canvas-aside-close" v-show="!menuAndDrawer">
      <span class="shousuo" @click="handlerisCollapse">
        <a-tooltip placement="top">
          <template slot="title">
            <span>菜单展开</span>
          </template>
          <!-- <i class="lowcode icon-zhuandao1"></i> -->
          <img src="@/assets/imgs/echarts/abb_right_32.svg" alt="">
        </a-tooltip>
      </span>
      <span class="deptName">
        {{ currentSelectDeptInfo.FULLNAME }}
      </span>
    </div>
    <div class="abb-canvas-container">
      <div class="abb-canvas-container-header">
        <draggable-tab :panellist="panellist" />
      </div>
      <div class="abb-canvas-container-content">
        <drag-board :panellist="panellist" />
      </div>
    </div>
    <el-dialog title="单线图" :visible.sync="dialogVisible" :destroy-on-close="true" @open="opendialog" width="99%"
      style="9999" :before-close="handleClose">
      <imgconfig :show="showd"></imgconfig>
      <span slot="footer" class="dialog-footer">
        <div style="background:white !important;color:black;color: black;" class="lxhbtn" @click="cancel">取 消</div>
        <div style="background:#3366FF !important" class="lxhbtn" @click="save">确 定</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import draggableTab from "@/views/widget/draggableTab.vue";
import dragBoard from "@/views/widget/dragBoard.vue";

import imgconfig from "@/component/chartStore/charFiles/lvscabinetCom/imgconfig.vue";
import { Message, MessageBox } from 'element-ui';
export default {
  name: "dashboardCanvas",
  components: {
    draggableTab, dragBoard, imgconfig
  },
  computed: {

    showimgconfig() {
      return this.$store.state.showimgconfig
    },
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo
    },
    allwidget() {
      return this.$store.state.allwidget
    },
    // 机构树是否为展开状态
    menuAndDrawer() {
      return this.$store.state.menuAndDrawer
    },

    // Dept数据
    allDeptData() {
      return this.$store.state.allDeptData
    },

    filterDeptData() {
      var value = this.searchValue
      if (!value) {
        return this.allDeptData
      }

      var arrList = [];//树结构扁平化处理
      this.deepChildren(this.allDeptData, arrList)
      var arr = arrList.filter(item => {
        return item.FULLNAME.indexOf(value) != -1
      })
      if (arr.length > 0) {
        return arr
      }

      return this.allDeptData
    },
    // // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    },
    defaultSelectedKeys() {
      return [this.currentSelectDeptInfo.CODE]
    },
    // 是否请求驾驶舱数据
    isRequestPanelInfo() {
      return this.$store.state.isRequestPanelInfo
    },
    // 是否执行删除widget
    isDelwidget() {
      return this.$store.state.isDelwidget
    },
  },
  watch: {
    showimgconfig(val) {
      if (val) {
        this.dialogVisible = val
        //console.log(val)
      }
    },
    isRequestPanelInfo(val) {
      if (val) {
        this.getpanelInfo('update')
      }
    },
    isDelwidget(val) {

      //console.log(val)
      if (val) {
        this.delwidget()
      }
    },
    // allwidget:{
    //     handler(newName, oldName) {

    //         },
    //         deep: true
    //   },
  },
  data() {
    return {
      showd: false,
      dialogVisible: false,
      replaceFields: {
        children: 'children',
        title: 'FULLNAME',
        key: 'CODE'
      },
      panellist: [],
      selectDeptInfo: {},//当前Dept信息
      searchValue: ''
    }
  },
  methods: {
    opendialog() {
      this.showd = !this.showd
    },
    handleClose() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    cancel() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    save() {
      this.$store.commit("setshowimgconfig", false);
      this.dialogVisible = false
    },
    deepChildren(arr, brr) {
      arr.forEach(item => {
        if (!!item.children) {
          this.deepChildren(item.children, brr)
        }
        var { children, ...obj } = item
        brr.push(obj)
      })
    },

    // 删除widget
    delwidget() {

      if (this.allwidget.id == 389 || this.allwidget.location == "old") {

        const url = Config.dev_url + "/api-apps/panel/add";
        var data = {
          location: this.$store.state.currentSelectDeptInfo.FULLNAME,//当前机构名
          type: this.currentSelectMenuInfo.name,//当前菜单名称
          index: 0,
          name: '总览',
          main: '0',
          value: JSON.stringify(this.allwidget.value)
        }
        var arr = this.allwidget.value
        var newArr = arr.filter(item => !item.isDel)
        data.value = JSON.stringify(newArr)
        this.$axios.post(url, data).then((res) => {
          if (res.data.code == 0) {
            // this.$message.success(res.data.msg)
            // this.dialogVisible = false
            // this.$store.commit("setisRequestPanelInfo", true)
            this.$message.success(res.data.msg)
          }
          this.$store.commit("setisRequestPanelInfo", true)
          this.$store.commit("setisDelwidget", false)

        }).catch((err) => { });
      } else {

        const url = Config.dev_url + "/api-apps/panel/updata";
        var data = {
          id: this.allwidget.id,
          value: []
        }
        var arr = this.allwidget.value
        var newArr = arr.filter(item => !item.isDel)
        data.value = JSON.stringify(newArr)
        this.$axios.post(url, data).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg)
            this.$store.commit("setisRequestPanelInfo", true)
          } else {
            this.$message.warning(res.data.msg)
          }
        })
          .catch((err) => { }).finally(() => {
            this.$store.commit("setisDelwidget", false)
          })
      }
    },
    getMenuName(name){
      let val = ""
      if(name == "overview" ){
        val = "总览"
      }else if(name =="energy"){
        val = "能源管理"
      }else if(name =="equipmentOverview"){
        val = "设备总览"
      }else if(name =="operationOverview"){
        val = "运维总览"
      }else if(name =="carbon"){
        val = "碳管理"
      }else{
        val = name
      }
      return val
    },
    //获取驾驶舱信息
    getpanelInfo(str = '') {
      const url = Config.dev_url + "/api-apps/panel/list";
      var data = {
        location: this.selectDeptInfo.FULLNAME,//当前机构名
        type: this.getMenuName(this.currentSelectMenuInfo.name)//当前菜单名称
      }
      this.$axios.get(url, { params: data }).then((res) => {
        if (res.data.code == 200) {

          var result = res.data.data.data
          result.forEach(item => {
            var tempArr = JSON.parse(item.value)
            item.value = tempArr || []
          })
          //console.log(result)
          var compare = function (obj1, obj2) {
            var val1 = parseInt(obj1.index);
            var val2 = parseInt(obj2.index);
            if (val1 < val2) {
              return -1;
            } else if (val1 > val2) {
              return 1;
            } else {
              return 0;
            }
          }
          //console.log(result.sort(compare));
          this.panellist = result.sort(compare)



          if (this.currentSelectMenuInfo.name == '总览') {
            let b = false
            for (let item of this.panellist) {

              if (item.name == '总览' && item.id != 389) {
                b = true
              }

            }

            if (b) {

            } else {

              let a = { "name": "总览", "location": "old", "main": "0", "id": 389, "index": 0, "paneluser": "admin", "value": "[{\"cname\":\"地图\",\"component\":\"energykanbanBox1\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":0,\"x\":0,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"总能耗详情\",\"component\":\"energykanbanBox7\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":1,\"x\":6,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"能源结构\",\"component\":\"powerBox1\",\"w\":3,\"h\":10,\"minw\":1,\"minh\":1,\"i\":2,\"x\":0,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"当前电能费用\",\"component\":\"powerBox21\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":3,\"x\":3,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"绿电消纳率\",\"component\":\"powerBox25\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":4,\"x\":0,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"光伏发电量排名\",\"component\":\"photovoltaicBox7\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":5,\"x\":6,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"年工单量统计\",\"component\":\"operationAm4\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":6,\"x\":0,\"y\":30,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放强度总览\",\"component\":\"carbonBox9\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":6,\"x\":6,\"y\":30,\"Draggable\":true,\"isResizable\":false,\"moved\":false}]", "paneltype": "总览" }
              var tempArr = JSON.parse(a.value)
              a.value = tempArr || []
              this.panellist.unshift(a)
            }
          }

          if (this.currentSelectMenuInfo.name == '能源管理') {
            let b = false
            for (let item of this.panellist) {

              if (item.name == '总览' && item.id != 389) {
                b = true
              }

            }
            if (b) {

            } else {
              let a = { "name": "总览", "location": "old", "main": "0", "id": 389, "index": 0, "paneluser": "admin", "value": "[{\"cname\":\"能源结构\",\"component\":\"powerBox1\",\"w\":3,\"h\":10,\"minw\":1,\"minh\":1,\"i\":0,\"x\":6,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"当前电能费用\",\"component\":\"powerBox21\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":2,\"x\":0,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"绿电消纳率\",\"component\":\"powerBox25\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":4,\"x\":0,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"电费分析\",\"component\":\"powerBox18\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":6,\"x\":6,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"电度电费分析\",\"component\":\"powerBox19\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":8,\"x\":0,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"综合能源收益\",\"component\":\"powerBox20\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":10,\"x\":6,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false}]", "paneltype": "总览" }
              var tempArr = JSON.parse(a.value)
              a.value = tempArr || []
              this.panellist.unshift(a)
            }

          }


          if (this.currentSelectMenuInfo.name == '运行状态') {
            let b = false
            for (let item of this.panellist) {

              if (item.name == '总览' && item.id != 389) {
                b = true
              }

            }

            if (b) {

            } else {
              let a = { "name": "总览", "location": "old", "main": "0", "id": 389, "index": 0, "paneluser": "admin", "value": "[{\"cname\":\"设备总数\",\"component\":\"energykanbanBox3\",\"w\":3,\"h\":10,\"minw\":1,\"minh\":1,\"i\":0,\"x\":0,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"电站发电量统计\",\"component\":\"photovoltaicBox6\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":1,\"x\":3,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"光伏发电量排名\",\"component\":\"photovoltaicBox7\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":2,\"x\":0,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"光伏收益分析\",\"component\":\"photovoltaicBox13\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":3,\"x\":6,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"配电功率利用率\",\"component\":\"lvscBox6\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":4,\"x\":0,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"断路器实时参数\",\"component\":\"lvscBox20\",\"w\":6,\"h\":5,\"minw\":1,\"minh\":1,\"i\":5,\"x\":3,\"y\":20,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"电池信息总览\",\"component\":\"chuneng5\",\"w\":3,\"h\":10,\"minw\":1,\"minh\":1,\"i\":7,\"x\":9,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false}]", "paneltype": "总览" }
              var tempArr = JSON.parse(a.value)
              a.value = tempArr || []
              this.panellist.unshift(a)
            }

          }



          if (this.currentSelectMenuInfo.name == '运维总览') {
            let b = false
            for (let item of this.panellist) {

              if (item.name == '总览' && item.id != 389) {
                b = true
              }

            }


            if (b) {

            } else {
              let a = { "name": "总览", "location": "old", "main": "0", "id": 389, "index": 0, "paneluser": "admin", "value": "[{\"cname\":\"工单总览\",\"component\":\"operationAm1\",\"w\":6,\"h\":5,\"minw\":1,\"minh\":1,\"i\":0,\"x\":0,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"工单量排行榜\",\"component\":\"operationAm2\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":2,\"x\":6,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"年工单量统计\",\"component\":\"operationAm4\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":4,\"x\":0,\"y\":5,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"当前工单总览\",\"component\":\"energykanbanBox4\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":6,\"x\":6,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"事件状态统计\",\"component\":\"operationAm6\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":8,\"x\":9,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"事件统计\",\"component\":\"operationAm7\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":10,\"x\":0,\"y\":15,\"Draggable\":true,\"isResizable\":false,\"moved\":false}]", "paneltype": "总览" }
              var tempArr = JSON.parse(a.value)
              a.value = tempArr || []
              this.panellist.unshift(a)
            }
          }




          if (this.currentSelectMenuInfo.name == '碳管理') {

            let b = false
            for (let item of this.panellist) {

              if (item.name == '总览' && item.id != 389) {
                b = true
              }

            }
            if (b) {

            } else {
              let a = { "name": "总览", "location": "old", "main": "0", "id": 389, "index": 0, "paneluser": "admin", "value": "[{\"cname\":\"碳排放详情\",\"component\":\"carbonBox1\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":0,\"x\":0,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放KPI\",\"component\":\"carbonBox4\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":2,\"x\":6,\"y\":0,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放分析\",\"component\":\"carbonBox6\",\"w\":6,\"h\":10,\"minw\":1,\"minh\":1,\"i\":4,\"x\":0,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放总览\",\"component\":\"carbonBox8\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":6,\"x\":6,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放强度总览\",\"component\":\"carbonBox9\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":8,\"x\":9,\"y\":10,\"Draggable\":true,\"isResizable\":false,\"moved\":false},{\"cname\":\"碳排放结构\",\"component\":\"carbonBox10\",\"w\":3,\"h\":5,\"minw\":1,\"minh\":1,\"i\":10,\"x\":6,\"y\":15,\"Draggable\":true,\"isResizable\":false,\"moved\":false}]", "paneltype": "总览" }
              var tempArr = JSON.parse(a.value)
              a.value = tempArr || []
              this.panellist.unshift(a)
            }
          }
          if (result.length > 0 && str != 'update') {
            this.$store.commit("setallwidget", result[0])
          }
          else {
            if (result.length == 0) {
              try{
              this.$store.commit("setallwidget", {})
              }catch(ex){
                console.log(ex)
              }
              return
            }
            var wId = this.allwidget.id
            var target = result.filter(item => item.id == wId)
            if (target.length > 0) {
              this.$store.commit("setallwidget", target[0])
            } else {
              this.$store.commit("setallwidget", result[0])
            }
          }
        }
        else {
          this.panellist = []
        }
        this.$store.commit("setpanellist", this.panellist)

      }).catch((err) => {
        this.panellist = []
      }).finally(() => {
        this.$store.commit("setcurrentSelectDeptInfo", this.selectDeptInfo)
        this.$store.commit("setisRequestPanelInfo", false)
      })
    },

    // 展开折叠树
    handlerisCollapse() {
      this.$store.commit('setmenuAndDrawer', !this.menuAndDrawer)
    },
    // 机构搜索
    onSearch(value) {
      //console.log(value);
    },
    // 获取一级树数据
    getFirstTreeData() {
      const url = Config.dev_url + "/api-apps/app/DEPT/findTree";
      var data = {
        codekey: "CODE",
        pcodekey: "PCODE",
      }
      this.$axios.post(url, data).then(res => {
        var result = res.data || [];
        if (result.length == 0) {
          this.getTreeData()
        }
        result.forEach(item => {
          if (item.haschilrensize) {
            // this.getChildrenData(item).then(data=> {
            //   item.children = data
            // })
          }
        })
        this.$store.commit("setallDeptData", result)
        if (result.length > 0) {
          this.setDeptInfo(result[0])
        }
      }).catch((wrong) => {
        this.$message.warning(wrong.msg)
      })
    },
    getTreeData() {
      // this.$store.state.currentuserinfo
      const url = Config.dev_url + "/api-apps/app/DEPT/findLayTree";
      var data = {
        codekey: "CODE",
        pcodekey: "PCODE",
      }
      this.$axios.post(url, data).then(res => {
        var result = res.data || [];
        result.forEach(item => {
          if (item.haschilrensize) {
            this.getChildrenData(item).then(data => {
              item.children = data
            })
          }
        })
        this.$store.commit("setallDeptData", result)
        if (result.length > 0) {
          this.setDeptInfo(result[0])
        }
      }).catch((wrong) => {
        this.$message.warning(wrong.msg)
      })
    },
    getTreeData2() {
      const url = Config.dev_url + "/api-apps/app/DEPT/findLayTree";
      var data = {
        CODE_wheres: 221027390,
        codekey: "CODE",
        pcodekey: "PCODE",
      }
      this.$axios.post(url, data).then(res => {
        var result = res.data || [];
        result.forEach(item => {
          if (item.haschilrensize) {
            this.getChildrenData(item).then(data => {
              item.children = data
            })
          }
        })
        this.$store.commit("setallDeptData", result)
        if (result.length > 0) {
          this.setDeptInfo(result[0])
        }
      }).catch((wrong) => {
        this.$message.warning(wrong.msg)
      })
    },
    async getChildrenData(item) {
      return await new Promise((resolve) => {
        const url = Config.dev_url + "/api-apps/app/DEPT/findLayTree";
        var data = {
          PCODE_wheres: item.CODE,
          codekey: "CODE",
          pcodekey: "PCODE",
        }
        var result = [];
        this.$axios.post(url, data).then(res => {

          result = res.data || [];
          result.forEach(item => {
            if (item.haschilrensize) {
              this.getChildrenData(item).then(data => {
                item.children = data
              })
            }
          })
          return resolve(result);
        }).catch((wrong) => {
          return resolve(result);
          // this.$message.warning(wrong.msg)
        }).finally(() => {
          //  this.$forceUpdate()
        })
      })


    },
    // 选中的机构
    onSelect(selectedKeys, info) {

      var item = info.node.dataRef
      this.setDeptInfo(item)
    },
    // 设置机构信息
    setDeptInfo(item) {
      this.selectDeptInfo = item
      const url = Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist";
      var data = {
        deptCode: this.selectDeptInfo.CODE,
      }
      var that = this;
      this.$axios.post(url, data).then(res => {
        //console.log(res)
        if (res.data.code == 0) {
          const result = res.data.data;
          var electricList = []
          var assetList = []
          if (result != null) {
            result.forEach(item => {
              if (item.assetnumber) {
                assetList.push(
                  {
                    code: item.assetNumber,
                    name: item.assetName,
                  }
                )
              }
            })
          }
          var itemurl = Config.dev_url + "/api-apps-v2/api/v2/master/equipment/types";
          this.$axios.post(itemurl, data).then(res => {
            const result = res.data.data;
            
            console.log(result)
            if (result != null) {


              result.forEach(item => {
                
                if (item != ""&&item!="探测器"&&item!="温湿度传感器"&&item!="消防设备"&&item!="空调") {
                  console.log(item)
                  electricList.push(
                    {
                      code: item,
                      name: item,
                    }
                  );
                }
              });
            }


            var dicOptions = {
              electricDic: electricList,
              assetDic: assetList
            }

            this.$store.commit("setdashboardOptions", dicOptions);

          }).finally(() => {

            this.getpanelInfo()

          });


        } else {
          this.$message.warning(res.data.msg)
        }
      })

      const url1 = Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist"
      this.$axios({
        url: url1,
        method: "post",
        data: {
          deptCode: this.selectDeptInfo.CODE,
          type: "电表"
        }
      }).then(res => {
        let result = res.data.data

        let arr = []
        if (result != null) {
          result.forEach(item => {
            let obj = {
              code: item.assetNumber,
              name: item.assetName
            }
            arr.push(obj)
          })
        }
        this.$store.commit("setDbDataFn", arr)
      })
    },
    // 通过机构CODE查询数据字典
    fetchDicByDept() {

    },
    //获取该应用下所有数据字典项
    getAppDictData() {
      const dictionaryCode =
        [
          "QUARTER",//季度
        ]
      const url = Config.dev_url + '/api-user/dicts/findByCodes';
      this.$axios.post(url, { code: [...new Set(dictionaryCode)].join(",") }).then((res) => {
        this.currentSelectMenuInfo.appDictData = res.data
      }).finally(() => {

      })
    },

    init() {
      //console.log(this.allDeptData)
      if (this.allDeptData.length == 0) {
        this.getAppDictData()
        this.getFirstTreeData()
      } else {
        this.selectDeptInfo = this.currentSelectDeptInfo
        this.getpanelInfo()
      }
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.init()
    this.$store.commit("setshowimgconfig", false);
    this.dialogVisible = false
  }
}
</script>

<style lang="less">
.abb-canvas {
  height: 100%;
  display: flex;

  .abb-canvas-aside,
  .abb-canvas-aside-close {
    margin-right: 16px;
  }

  .abb-canvas-aside {
    width: 280px;
    height: inherit;
    padding: 16px 8px;
    background-color: #ffffff;
    box-sizing: border-box;

    .abb-aside-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      /deep/.ant-input-search {
        width: 232px;
      }

      .shousuo {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        // transform: scaleX(-1);
        &:hover {
          background: #dfe7fc;
          color: #205de8;
          border-radius: 2px;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        }
      }
    }

    .deptTree {
      height: calc(100vh - 145px);
      overflow: auto;
      // background: red;
    }

    /deep/.ant-tree {
      li {}

      .ant-tree-icon__customize {
        margin-right: 8px;

      }

      .ant-tree-switcher_close {
        .icon-right {
          &::before {
            content: "\e76b";
          }
        }
      }

      .ant-tree-switcher_open {
        .icon-right {
          &::before {
            content: "\e76c";
          }
        }
      }

      .ant-tree-treenode-selected {
        background: rgba(208, 222, 255, 0.60);
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        .ant-tree-node-selected {
          background-color: transparent;

          span {
            color: #205DE8;
            font-size: 14px;
            font-weight: 400;
          }

        }

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 40px;
          top: 0;
          background: #205de8;
          border-radius: 4px 0px 0px 4px;
        }
      }

      .ant-tree-node-content-wrapper {
        &:hover {
          background-color: transparent;
        }
      }



    }

  }

  .abb-canvas-aside-close {
    width: 48px;
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;

    .shousuo {
      position: absolute;
      top: 20px;
      left: 12px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: #dfe7fc;
        color: #205de8;
        border-radius: 2px;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
    }

    .deptName {
      word-wrap: break-word;
      /*英文的时候需要加上这句，自动换行*/
      writing-mode: vertical-rl;
      /*从左向右 从右向左是 writing-mode: vertical-rl;*/
      writing-mode: tb-rl;
      /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
      text-align: center;
      padding: 0px;
      display: flex;
      -webkit-display: flex;
      flex-direction: column;
      justify-content: center;

      font-size: 14px;
      font-weight: 600;
      color: #1f1f1f;
      text-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
  }

  .abb-canvas-container {
    flex: 1;

    /* 整个滚动条 */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }


    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
      background-color: #fff;
      /* 关键代码 */
      // background-image: -webkit-linear-gradient(45deg,
      //         rgba(255, 255, 255, 0.4) 25%,
      //         transparent 25%,
      //         transparent 50%,
      //         rgba(255, 255, 255, 0.4) 50%,
      //         rgba(255, 255, 255, 0.4) 75%,
      //         transparent 75%,
      //         transparent);
      border-radius: 32px;
    }

    /* 滚动条轨道 */
    ::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 32px;
    }

    .abb-canvas-container-header {
      min-height: 72px;
    }

    .abb-canvas-container-content {
      height: calc(100vh - 141px);
    }
  }

}</style>
