<template>
  <div class="add">
    <el-dialog
      title="添加组件"
      :visible.sync="dialogVisible"
      width="45%"
      :modal="false"
      
      >
      <div style="width:100%">
        <el-row style="overflow-y: scroll;height: 360px;">
        <el-col :span="5" style="position: relative;">
          <input placeholder='关键字'
                 style='font-size: 12px;width: 93%;height: 32px;background: #FFFFFF;border: 1px solid #D9D9D9;border-radius: 6px;padding-left: 12px;margin-bottom: 12px;' />
          <i class='el-icon-search' style='position: absolute;right: 20px;top: 9px;'></i>
          <el-menu>
            <el-menu-item index="2" style="">
              <i class="el-icon-menu" ></i>
              <span >能源类</span>
              <span style="position: absolute;right: 8px;color:#9d9d9d">10</span>
            </el-menu-item>
            <el-menu-item index="3" style="">
              <i class="el-icon-document"></i>
              <span >设备类</span>
              <span style="position: absolute;right: 8px;color:#9d9d9d">10</span>
            </el-menu-item>
            <el-menu-item index="4" style="">
              <i class="el-icon-setting"></i>
              <span >运维类</span>
              <span style="position: absolute;right: 8px;color:#9d9d9d">10</span>
            </el-menu-item>
            <el-menu-item index="5" style="">
              <i class="el-icon-setting"></i>
              <span>碳管理</span>
              <span style="position: absolute;right: 8px;color:#9d9d9d">10</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        
        <el-col :span="19" style="padding-left: 10px;" v-if="$store.state.currentSelectMenuInfo.name=='总览'">
          <el-checkbox-group v-model="checkList">
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart1.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前碳排放总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;font-size: 14px;">显示公司当前碳排放的总体情况</div>
                <div>
                  <div style="font-size: 14px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                </div>
              </div>
            </div>
            <div>
             <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(1)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart2.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">设备总数</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前设备总数情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(6)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart3.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">地图</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前地图情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(2)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart4.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前工单总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前工单情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(3)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart5.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">电量使用排行</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前电量使用情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(4)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart6.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">电量使用排名</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前电量排名情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(5)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart7.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能源监测总能耗</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前设备能源监测总能耗情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(8)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart9.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前总能耗</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前当前总能耗情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(9)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart8.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前工单总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前工单总览情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(7)"></i>
            </div>
          </div>
          </el-checkbox-group>
        </el-col>
        <el-col :span="19" style="padding-left: 10px;" v-if="$store.state.currentSelectMenuInfo.name=='能源管理'">
          <el-checkbox-group v-model="checkList">
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart10.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能源结构</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;font-size: 14px;">显示公司当前能源结构的总体情况</div>
                <div>
                  <div style="font-size: 14px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                </div>
              </div>
            </div>
            <div>
             <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(10)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart14.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能源KPI排名(公司)</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前能源KPI排名情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(11)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart21.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">电能分项</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前电能分项情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(12)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart13.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">变压器负载监测</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前变压器负载监测情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(13)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart17.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能耗热力图</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前能耗热力图使用情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(14)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart16.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能源流向</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前能源流向情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(15)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart12.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">质量总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前质量总览情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(16)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart11.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">功率检测</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前功率检测情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(17)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart19.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">电流畸变率</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前电流畸变率情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(18)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart22.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">功率检测</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前功率检测情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(19)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart15.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">需量分析</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前需量分析情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(20)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart18.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">购电分析</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前购电分析情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(21)"></i>
            </div>
          </div>
          </el-checkbox-group>
        </el-col>
        <el-col :span="19" style="padding-left: 10px;" v-if="$store.state.currentSelectMenuInfo.name=='设备管理'">
          <el-checkbox-group v-model="checkList">
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart23.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">配电室环境</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;font-size: 14px;">显示配电室环境总体情况</div>
                <div>
                  <div style="font-size: 14px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                </div>
              </div>
            </div>
            <div>
             <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(22)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart24.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">网关连接状态</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示网关连接状态情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(23)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart25.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">设备利用率</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示设备利用率情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(24)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart26.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">负载率</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示负载率情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(25)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart27.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前告警</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示当前告警情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(26)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart28.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">设备在线状态</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示设备在线状态情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(27)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart29.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">低压配电系统电参量</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示低压配电系统电参量情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(28)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart30.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">最新事件</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司最新事件</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(29)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart31.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">开关柜基础数据</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示公司当前开关柜基础数据</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(30)"></i>
            </div>
          </div>
          </el-checkbox-group>
        </el-col>
        <el-col :span="19" style="padding-left: 10px;" v-if="$store.state.currentSelectMenuInfo.name=='运维管理'">
          <el-checkbox-group v-model="checkList">
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart32.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">工单类型分析</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;font-size: 14px;">显示工单类型分析情况</div>
                <div>
                  <div style="font-size: 14px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                </div>
              </div>
            </div>
            <div>
             <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(31)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart33.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">年工单量统计</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">显示年工单量统计情况</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(32)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart34.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">当前工单总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">当前工单总览</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(33)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart35.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">待办工单</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">待办工单</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(34)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart36.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">运维资讯状态</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">运维资讯状态</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(35)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart37.png"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">运维资讯列表</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">运维资讯列表</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(36)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart38.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">年运营规则</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">年运营规则</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(37)"></i>
            </div>
          </div>
          </el-checkbox-group>
        </el-col>
        <el-col :span="19" style="padding-left: 10px;" v-if="$store.state.currentSelectMenuInfo.name=='碳管理'">
          <el-checkbox-group v-model="checkList">
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart39.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">碳排放结构</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;font-size: 14px;">碳排放结构</div>
                <div>
                  <div style="font-size: 14px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                </div>
              </div>
            </div>
            <div>
             <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(38)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart40.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">碳排放总览</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">碳排放总览</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(39)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart41.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">碳排名</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">碳排名</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(40)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart42.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">碳交易</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">碳交易</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(41)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart43.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">能源减碳率</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">能源减碳率</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(42)"></i>
            </div>
          </div>
          <div style="background: #ffffff;border-radius: 4px;height: 120px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;padding: 20px;">
            <div style="display:flex">
              <div style="background: #f6f9fd;height: 82px;width: 120px;padding: 10px;margin-right: 20px;">
                <img style="width: 100%;height: 100%;" src="@/assets/imgs/add/chart44.jpg"/>
              </div>
              <div>
                <div style="margin-bottom: 15px;font-size: 15px;">碳规划</div>
                <div style="color:rgb(173 178 183);margin-bottom: 15px;    font-size: 14px;">碳规划</div>
                <div style="display:flex">
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">能源</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">设备</div>
                  <div style="    font-size: 14px;margin-right: 5px;width: 43px;height: 22px;background: #f5f5f5;border-radius: 2px;line-height: 22px;text-align: center;">监控</div>
                </div>
              </div>
            </div>
            <div>
              <i style="font-size: 17px;color: #adadad;cursor: pointer;" class="el-icon-circle-plus" @click="updata(43)"></i>
            </div>
          </div>
          </el-checkbox-group>
        </el-col>
        </el-row>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="updata">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'add',
  data() {
    return {
      checkList:[],
      dialogVisible: false,
      checked: true
    }
  },
  watch: {
      show(val,val2) {//titleDec即为父组件的值，val参数为值
         
          this.dialogVisible = true
          
      },
    },
  props:["show"],
  methods: {
    random(){
      var charactors="1234567890"; 
      var value='',i; 
      for(let j=1;j<=4;j++){ 
          i = parseInt(10*Math.random()); 　
          value = value + charactors.charAt(i);
        }
        return value
    },
    updata(index){
      let arr = JSON.parse(this.$store.state.allwidget.value)
        if(index == '1'){
          arr.push({ x: 0, y: 0, w: 3, h:5, i: this.random(),minw:3,minh:5,c:1,Draggable:false})
        }else if(index == '2'){
          arr.push({ x: 3, y: 0, w: 6, h: 5, i: this.random(),c:2,minw:6,minh:5,Draggable:false})
        }else if(index == '3'){
          arr.push({ x: 9, y: 0, w: 3, h: 5, i: this.random(),c:3,minw:3,minh:5,Draggable:false })
        }else if(index == '4'){  
          arr.push({ x: 0, y: 2, w: 3, h: 5, i: this.random(),c:4,minw:3,minh:5,Draggable:false })
        }else if(index == '5'){
          arr.push({ x: 3, y: 2, w: 6, h: 5, i: this.random(),c:5,minw:6,minh:5,Draggable:false })
        }else if(index == '6'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:6,minw:3,minh:5,Draggable:false})
        }else if(index == '7'){
          arr.push({ x: 0, y: 2, w: 3, h: 5, i: this.random(),c:7,minw:3,minh:5,Draggable:false})
        }else if(index == '8'){
          arr.push({ x: 3, y: 2,w: 6, h: 10, i: this.random(),c:8,minw:6,minh:10,Draggable:false })
        }else if(index == '9'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:9,minw:3,minh:5,Draggable:false })

        }else if(index == '10'){
          arr.push({ x: 9, y: 2, w: 3, h: 6, i: this.random(),c:1,minw:3,minh:5,Draggable:false })
        }else if(index == '11'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:2,minw:3,minh:5,Draggable:false })
        }else if(index == '12'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:3,minw:3,minh:5,Draggable:false })
        }else if(index == '13'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:4,minw:3,minh:5,Draggable:false })
        }else if(index == '14'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:5,minw:3,minh:5,Draggable:false })
        }else if(index == '15'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:6,minw:3,minh:5,Draggable:false })
        }else if(index == '16'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:7,minw:3,minh:5,Draggable:false })
        }else if(index == '17'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:8,minw:3,minh:5,Draggable:false })
        }else if(index == '18'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:9,minw:3,minh:5,Draggable:false })
        }else if(index == '19'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:10,minw:3,minh:5,Draggable:false })
        }else if(index == '20'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:11,minw:3,minh:5,Draggable:false })
        }else if(index == '21'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:12,minw:3,minh:5,Draggable:false })

        }else if(index == '22'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:1,minw:3,minh:5,Draggable:false })
        }else if(index == '23'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:2,minw:3,minh:5,Draggable:false })
        }else if(index == '24'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:3,minw:3,minh:5,Draggable:false })
        }else if(index == '25'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:4,minw:3,minh:5,Draggable:false })
        }else if(index == '26'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:5,minw:3,minh:5,Draggable:false })
        }else if(index == '27'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:6,minw:3,minh:5,Draggable:false })
        }else if(index == '28'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:7,minw:3,minh:5,Draggable:false })
        }else if(index == '29'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:8,minw:3,minh:5,Draggable:false })
        }else if(index == '30'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:9,minw:3,minh:5,Draggable:false })
        }else if(index == '31'){

          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:1,minw:3,minh:5,Draggable:false })
        }else if(index == '32'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:2,minw:3,minh:5,Draggable:false })
        }else if(index == '33'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:3,minw:3,minh:5,Draggable:false })
        }else if(index == '34'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:4,minw:3,minh:5,Draggable:false })
        }else if(index == '35'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:5,minw:3,minh:5,Draggable:false })
        }else if(index == '36'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:6,minw:3,minh:5,Draggable:false })
        }else if(index == '37'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:7,minw:3,minh:5,Draggable:false })
        }else if(index == '38'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:1,minw:3,minh:5,Draggable:false })
        }else if(index == '39'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:2,minw:3,minh:5,Draggable:false })
        }else if(index == '40'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:3,minw:3,minh:5,Draggable:false })
        }else if(index == '41'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:4,minw:3,minh:5,Draggable:false })
        }else if(index == '42'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:5,minw:3,minh:5,Draggable:false })
        }else if(index == '43'){
          arr.push({ x: 9, y: 2, w: 3, h: 5, i: this.random(),c:6,minw:3,minh:5,Draggable:false })
        }
      console.log(arr)

      
      var url = Config.dev_url + "/api-apps/panel/updata";
      this.$axios
          .post(url, {
            id:this.$store.state.allwidget.id,
            value:JSON.stringify(arr)
          })
          .then((res) => {
              this.checkList = []
          })
          .catch((err) => {});
       

      this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang='less'>

</style>
