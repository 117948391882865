<template>
  <div class="add">
    <el-dialog
      class="custom-dialog" 
      :title="LangHandler('add2add','添加驾驶舱')"
      :visible.sync="dialogVisible"
      width="45%"
      
      >
      <div style="width:100%">
        <el-row>
          <div>
            <el-radio-group v-model="radio" style="display: flex;">
              <div style="width: 212px;height: 88px;display: flex;background: white;border-radius: 10px;align-items: center;justify-content: space-between;    padding-left: 15px;padding-right: 10px;">
                <div style="height: 47px;">
                  <img style="width: 50px;" src="@/assets/imgs/1679214333228.jpg" />
                </div>
                <div style="font-size: 16px;font-weight: 600;">{{LangHandler('add2create','新建驾驶舱')}}</div>
                <el-radio style="height: 50px;" :label="true"></el-radio>
              </div>
              <!-- <div style="width: 212px;height: 88px;display: flex;background: white;border-radius: 10px;align-items: center;justify-content: space-between;    padding-left: 15px;padding-right: 10px;margin-left: 20px;">
                <div style="height: 47px;">
                  <img style="width: 50px;" src="@/assets/imgs/add2.jpg" />
                </div>
                <div style="font-size: 16px;font-weight: 600;">从已有复制</div>
                <el-radio style="height: 50px;" :label="false"></el-radio>
              </div> -->
            </el-radio-group>
          </div>
          <div style="margin-top: 30px;">
            <div style="margin-bottom: 10px;"><span style="color:red">*</span>{{LangHandler('add2name','驾驶舱名称')}}</div>
            <div>
              <a-input style="width: 250px;" v-model="value" :placeholder="LangHandler('add2input','请输入驾驶舱名称')" />
            </div>
          </div>
          <div style="margin-top: 30px;" v-if="radio == false">
            <div style="margin-bottom: 10px;"><span style="color:red">*</span>{{LangHandler('add2copy','复制驾驶舱')}}</div>
            <div>
              <a-select
                ref="select"
                dropdownClassName="panelDropdown"
                v-model="value1"
                style="width: 250px"
                :options="panellist"
                @change="panelchange"
                :getPopupContainer="triggerNode => triggerNode.parentNode"
              >
              </a-select>
            </div>
          </div>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
        <el-button size="mini" @click="dialogVisible = false">{{LangHandler('cancel','取消')}}</el-button>
        <el-button size="mini" type="primary" style="background:#3366FF" @click="add">{{LangHandler('custheaddetermine','确定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'add',
  data() {
    return {
      value:"",//驾驶舱名称
      value1:"",//被复制驾驶舱
      choosepanel:"",
      options1:[
        {
          value: 'jack',
          label: 'Jack',
        },
        {
          value: 'lucy',
          label: 'Lucy',
        },
        {
          value: 'yiminghe',
          label: 'Yiminghe',
        },
      ],
      radio:true,
      dialogVisible: false,
      checked: true,
      panellist:[]
    }
  },
  computed:{
    // 当前菜单
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    },
  },
  watch: {

    },
  methods: {
    open() {
      this.value = ''
      this.value1 = ''
      this.choosepanel = ''


      this.dialogVisible = true
      this.getpanelInfo()
    },
    panelchange(value,option){
      this.choosepanel = option.data.props.value
    },
    add(){
      
      const url = Config.dev_url + "/api-apps/panel/add";
      var data = {
            location: this.currentSelectDeptInfo.FULLNAME,//当前机构名
            type: this.currentSelectMenuInfo.name,//当前菜单名称
            name: this.value,
            index:this.$store.state.panellist.length+1,
            main:'0',
            value:'[]'
      }
      if(!this.value) {
        this.$message.warning("请输入驾驶舱名称")
        return
      }

      if(!this.radio) {//从已有复制
        data.value = this.choosepanel

        if(!this.value1) {
          this.$message.warning("请选择被复制的驾驶舱名称")
          return
        }

      }
      this.$axios.post(url,data).then((res) => {
        if(res.data.code == 0) {
          this.$message.success(res.data.msg)
          this.dialogVisible = false
          this.$store.commit("setisRequestPanelInfo", true)
          let vm = this
          
          setTimeout(() => { 
           
            console.log()
            vm.$store.commit("setallwidget", vm.$store.state.panellist[vm.$store.state.panellist.length-1]);
          }, 500);
          
        }


      }).catch((err) => {});      
    },
    getMenuName(name){
      let val = ""
      if(name == "overview" ){
        val = "总览"
      }else if(name =="energy"){
        val = "能源管理"
      }else if(name =="equipmentOverview"){
        val = "设备总览"
      }else if(name =="operationOverview"){
        val = "运维总览"
      }else if(name =="carbon"){
        val = "碳管理"
      }else{
        val = name
      }
      return val
    },
    getpanelInfo() {
      const url = Config.dev_url + "/api-apps/panel/list";
      var data = {
        location: this.currentSelectDeptInfo.FULLNAME,//当前机构名
        type: this.getMenuName(this.currentSelectMenuInfo.name)//当前菜单名称
      }
      this.$axios.get(url, {params: data}).then((res) => {
        if(res.data.code == 200) {
            var result = res.data.data.data
            for(let item of result){
              item.label = item.name
            }
            this.panellist = result
          } 
         else {
          this.panellist = []
        }
      }).catch((err) => {
        this.panellist = []
      });
        },
  },
  mounted(){
    
  }
}
</script>

<style scoped lang='less'>

</style>
