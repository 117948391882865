<template>
    <a-modal dialogClass="storeModal" :title="option.title" :okText="option.okText" :cancelText="option.cancelText" v-model="visible" :closable="true" :keyboard="false" @ok="handleOk" @cancel="handleCancel">
        <div class="storeBody">
            <!-- <div class="titleTab" v-if="activeMenu == '能源管理'">
                <div class="titleTab_name" v-for="(item) in newData"  :key="item.id" :class ="{'d_active':item.id == setID}" @click="titleTab(item)">
                    {{item.name}}
                </div>
            </div>
            <div class="titleTab" v-if="activeMenu == '设备管理'">
                <div class="titleTab_name" v-for="(item) in newData1" :key="item.id" :class ="{'d_active':item.id == setID}" @click="titleTab(item)">
                    {{item.name}}
                </div>
            </div> -->
            <div class="asideList">
                <div class="asideSearch">
                    <a-input-search :placeholder="LangHandler('chartStoresearch','搜索组件名称')" v-model="searchValue"  @search="onSearch" />
                </div>
                <ul>
                    <li v-for="(item,idx) in list" :key="idx" :class="{ 'c_active': item.type == activeMenu }" @click="changeType(item)">
                        <div style="overflow: hidden">
                          <img src="@/assets/imgs/echarts/nengyuan.svg" style="width: 16px;height: 16px;margin-right: 8px;position: relative;top: 2px" v-if="item.type=== '能源管理'">
                          <img src="@/assets/imgs/echarts/shebei.svg" style="width: 16px;height: 16px;margin-right: 8px;position: relative;top: 1px" v-else-if="item.type=== '设备管理'">
                          <img src="@/assets/imgs/echarts/yunwei.svg" style="width: 16px;height: 16px;margin-right: 8px;position: relative;top: 3px" v-else-if="item.type=== '运维管理'">
                           <img src="@/assets/imgs/big/CM.svg" style="width: 16px;height: 16px;margin-right: 8px;position: relative;top: 3px" v-else-if="item.type=== '碳管理'">
                            <i :class="item.icon" v-else></i>
                            <font>{{ item.title }}</font>
                        </div>
                        <div class="typecount">
                            <font>{{ item.count }}</font>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="bodyContent">
                <div class="titleTab" v-if="activeMenu == '能源管理'">
                    <div class="titleTab_name" v-for="(item) in newData"  :key="item.id" :class ="{'d_active':item.id == setID}" @click="titleTab(item)">
                    {{item.name}}
                </div>
                </div>
                <div class="titleTab" v-if="activeMenu == '设备管理'">
                <div class="titleTab_name" v-for="(item) in newData1" :key="item.id" :class ="{'d_active':item.id == setID}" @click="titleTab(item)">
                    {{item.name}}
                </div>
                </div>
                <ul :style="{'height': (activeMenu == '设备管理' || '能源管理') ? '505px' : '545px'}">
                    <li v-for="(item,idx) in filterList" :key="idx"  @click="checkChoose(item)" :style="{display:showornot(item)}">
                        <div class="item-left">
                            <div class="item-micro">
                                <span>
                                    <img :src="microInfo[item.microPicture]"/>
                                </span>
                                <div v-show="item.index != 0" style="width: 15px;height: 15px;background: #3c8aea;color: white;text-align: center;font-size: 12px;line-height: 15px;border-radius: 20px;position: absolute;right: 5px;top: 5px;">{{item.index}}</div>
                            </div>
                            <div class="item-content">
                                <div>{{ item.title }}</div>
                                <div>{{ item.describe }}</div>
                                <div class="item-tags" >
                                    <span v-for="(tag,idx) in item.tag" :key="idx">{{ tag }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="item-check" :class="{ 'isCheck': item.isCheck }">
                            <span></span>
                        </div>
                    </li>
                </ul>


            </div>
        </div>
    </a-modal>
</template>
<script>
    import list6 from "@/component/chartStore/dataBase/lvscabinet_LP.json";

    export default {
        name: 'chartStore',
        components: {
            
        },
        props: {

        },
        watch: {

        },
        data() {
            return {
                option: {
                    title: this.LangHandler('add1addmoudle','添加组件'),
                    okText: this.LangHandler('custheaddetermine','确定'),
                    cancelText: this.LangHandler('cancel','取消'),
                },
                newData:[
                    {
                        name:this.LangHandler('chartenergyanalyze','能源分析'),
                        id:1,
                    },
                    // {
                    //     name:'电能分项',
                    //     id:2,
                    // },
                    {
                        name:this.LangHandler('chartpowerqu','电能质量'),
                        id:3,
                    },
                    {
                        name:this.LangHandler('chartcostanalyze','电费分析'),
                        id:4,
                    },
                ],
                newData1:[
                    {
                        name:this.LangHandler('chartpv','光伏设备'),
                        id:1,
                    },
                    {
                        name:this.LangHandler('chartdistribution','配电设备'),
                        id:2,
                    },
                    {
                        name:this.LangHandler('chartstored','储能设备'),
                        id:3,
                    },
                ],
                setID:0,
                leftName:"",
                visible: false,
                list: [
                    // { title:"全部",count: 12,icon: "lowcode icon-yonghu3"  },
                    // { title:"能源类",count: 12,icon: "lowcode icon-shandian"  },
                    // { title:"监控类",count: 10,icon: "lowcode icon-weishoucang"  },
                    // { title:"工作类",count: 10,icon: "lowcode icon-bengkui"  },
                    // { title:"其他类",count: 10,icon: "lowcode icon-zhinanzhen"  },
                    // { title:"储能",count: 10,icon: "lowcode icon-zhinanzhen"  },
                ],
                activeMenu: "全部",
                allList: {},
                tableList: [],
                searchValue: '',
                microInfo: {}
            }
        },
        
        computed:{
            currentSelectMenuInfo() {
                return this.$store.state.currentSelectMenuInfo
            },
            // 当前驾驶舱
            allwidget() {
               
                return this.$store.state.allwidget
            },

            filterList() {
                
                
                var val = this.searchValue
               
                // console.log(this.$store.state.allwidget)
                // console.log(this.tableList)

                let a = this.tableList.filter(item=>{
                    if (this.activeMenu == "能源管理") {
                        if (this.setID == 1) {
                            if (item.tag[0].indexOf("能源分析") != -1) {
                                    return item
                            }
                        }else if(this.setID == 2){
                           if (item.tag[0].indexOf("电能分项") != -1) {
                                    return item
                            }
                        }else if(this.setID == 3){
                           if (item.tag[0].indexOf("电能质量") != -1) {
                                    return item
                            }
                        }else if(this.setID == 4){
                           if (item.tag[0].indexOf("电费分析") != -1) {
                                    return item
                            }
                        }else{
                            if (item['title'].indexOf(val)>-1 ||item['title'].toUpperCase().indexOf(val.toUpperCase())>-1){
                            return item;
                        }   
                        }
                    }else if(this.activeMenu == "设备管理"){
                        if(this.setID == 1){
                           if (item.tag[0].indexOf("光伏设备") != -1) {
                                    return item
                            }
                        }else if(this.setID == 2){
                           if (item.tag[0].indexOf("配电设备") != -1) {
                                    return item
                            }
                        }else if(this.setID == 3){
                           if (item.tag[0].indexOf("储能设备") != -1) {
                                    return item
                            }
                        }else{
                            if (item['title'].indexOf(val)>-1 ||item['title'].toUpperCase().indexOf(val.toUpperCase())>-1){
                            return item;
                        }   
                        }
                    }else{
                        if (item['title'].indexOf(val)>-1 ||item['title'].toUpperCase().indexOf(val.toUpperCase())>-1){
                            return item;
                        }                       
                    }
                })
                if(this.$store.state.allwidget.value!=undefined){
                var arr = JSON.parse(JSON.stringify(this.$store.state.allwidget.value))
                for(let items of a){
                    items.index = 0
                }
                for(let item of arr){
                    for(let items of a){
                        if(item.cname == items.layout.cname){
                            items.index = items.index+1
                        }
                    }
                }
            }
                //console.log(a)
                return  a
            },
        },
        methods: {
            handleCancel(){
                this.tableList.forEach(item=>item.isCheck = false)
            },
            filtercont(arr){
                let contList = []
                arr.forEach((item)=>{
                    if(!(this.$store.state.currentSelectDeptInfo.DEPTTYPE!='站点'&&item.title=='能源流向')){
                        if(!(this.$store.state.currentSelectDeptInfo.DEPTTYPE=='站点'&&item.title=='碳排放KPI')){
                            if(!(this.$store.state.currentSelectDeptInfo.DEPTTYPE=='站点'&&item.title=='公司能源KPI')){
                                if(!(this.$store.state.currentSelectDeptInfo.DEPTTYPE!='站点'&&item.title=='碳排放输入')){
                                    contList.push(item)
                                }
                            }
                        }
                    }
                })
                return contList.length
            },
            showornot(item){
                if(this.$store.state.currentSelectDeptInfo.DEPTTYPE!='站点'&&item.title=='能源流向'){
                    return 'none'
                }if(this.$store.state.currentSelectDeptInfo.DEPTTYPE=='站点'&&item.title=='碳排放KPI'){
                    return 'none'
                }if(this.$store.state.currentSelectDeptInfo.DEPTTYPE!='站点'&&item.title=='碳排放输入'){
                    return 'none'
                }if(this.$store.state.currentSelectDeptInfo.DEPTTYPE=='站点'&&item.title=='能源成本排名'){
                    return 'none'
                }if(this.$store.state.currentSelectDeptInfo.DEPTTYPE=='站点'&&item.title=='公司能源KPI'){
                    return 'none'
                }else{
                    return 'flex'
                }
               
            },
            titleTab(item){

              this.setID = item.id
            },
            open() {
                this.activeMenu = ''
                this.searchValue = ''
                this.microInfo = {
                    pillar: require("@/assets/imgs/echarts/柱状图.jpg"),
                    break: require("@/assets/imgs/echarts/折线图.jpg"),
                    Pie: require("@/assets/imgs/echarts/饼状图.jpg"),
                    table: require("@/assets/imgs/echarts/表格.jpg"),
                    Dashboard: require("@/assets/imgs/echarts/仪表盘.jpg"),
                    dataSet: require("@/assets/imgs/echarts/数据组.jpg"),
                    seat: require('@/assets/imgs/echarts/定位.jpg'),
                    pie: require("@/assets/imgs/chartMicro/pie.png"),
                    radar: require("@/assets/imgs/chartMicro/radar.png"),
                    stackarealine: require("@/assets/imgs/chartMicro/stackarealine.png"),
                }
                this.visible = true
                let lang = sessionStorage.getItem("locale") || 'CH';
              
                if(lang == 'CH'){
                    var list1 = require('./dataBase/Base1.json')
                    var list2 = require('./dataBase/Base2.json')
                    var list3 = require('./dataBase/CDZ_CP.json')
                    var list4 = require('./dataBase/carbon_W.json')
                    var list5 = require('./dataBase/dieselgenerator_DG.json')
                    var list6 = require('./dataBase/lvscabinet_LP.json')
                    var list7 = require('./dataBase/photovoltaic_L.json')
                    var list8 = require('./dataBase/energykanban_D.json')
                    var list9 = require('./dataBase/power_W.json')
                    var list10 = require('./dataBase/Base3.json')
                    var list11 = require('./dataBase/Base4.json')
                }else{
                    var list1 = require('./dataBase/Base1.json')
                    var list2 = require('./dataBase/Base2.json')
                    var list3 = require('./dataBase/CDZ_CP.json')
                    var list4 = require('./dataBase/carbon_W_EN.json')
                    var list5 = require('./dataBase/dieselgenerator_DG.json')
                    var list6 = require('./dataBase/lvscabinet_LP.json')
                    var list7 = require('./dataBase/photovoltaic_L.json')
                    var list8 = require('./dataBase/energykanban_D_EN.json')
                    var list9 = require('./dataBase/power_W_EN.json')
                    var list10 = require('./dataBase/Base3.json')
                    var list11 = require('./dataBase/Base4_EN.json')
                }
                

                var allList = [...list1]
                allList.forEach((item,idx)=>{
                    item.isCheck = false
                    item.id = idx
                })
                list8.forEach(element => {
                    element.tag.forEach(item=>{
                        //console.log(item);
                    })
                });
                this.allList = {
                    // "全部": allList,
                    // "能源类": list1,
                    // "其他": list2,
                    // "充电桩": list3,
                    "能源管理": list9,
                    "设备管理": list8,
                    "运维管理": list11,
                    "碳管理": list4,
                    // "柴油发电机":list5,
                    // "储能": list10,
                    // "低压柜" : list6

                }
                this.list = [
                    // { id:1,title:"全部",count: allList.length,icon: "lowcode icon-yonghu3"  },
                    { id:2,title:this.commonLangHandler('energy_tiele','能源管理', this.getZEdata),type:"能源管理",count: this.filtercont(list9),icon: "lowcode icon-shandian"  },
                    { id:3,title:this.commonLangHandler('equipment_title','设备管理', this.getZEdata),type:"设备管理",count: this.filtercont(list8),icon: "lowcode icon-weishoucang"  },
                    { id:4,title:this.commonLangHandler('operation_title','运维管理', this.getZEdata),type:"运维管理",count: this.filtercont(list11),icon: "lowcode icon-zhinanzhen"  },
                    { id:5,title:this.commonLangHandler('carbon_title','碳管理', this.getZEdata),type:"碳管理",count: this.filtercont(list4),icon: "lowcode icon-zhinanzhen"  },
                    // { id:6,title:"储能",count: list10.length,icon: "lowcode icon-zhinanzhen"  },
                    // { id:7,title:"低压柜",count: list6.length,icon: "lowcode icon-zhinanzhen"  },
                    // { id:8,title:"充电桩",count: list3.length,icon: "lowcode icon-zhinanzhen"  },
                    // { id:9,title:"柴油发电机",count: list5.length,icon: "lowcode icon-zhinanzhen"  },
                ]
                this.tableList = this.allList['能源管理']
            },
            changeType(item) {
                this.setID = 0
                if(this.activeMenu != item.type || !this.activeMenu) {
                    this.activeMenu = item.type
                    //console.log(this.activeMenu)
                    this.tableList = this.allList[item.type]
                    this.tableList.forEach(item=>item.isCheck = false)
                }
            },
            handleOk() {
                if(this.allwidget.id == 389 || this.allwidget.location == "old"){
                    
                    const url = Config.dev_url + "/api-apps/panel/add";
                    var data = {
                            location: this.$store.state.currentSelectDeptInfo.FULLNAME,//当前机构名
                            type: this.currentSelectMenuInfo.name,//当前菜单名称
                            index:0,
                            name: '总览',
                            main:'0',
                            value:JSON.stringify(this.allwidget.value)
                    }
                    var arr = JSON.parse(JSON.stringify(this.allwidget.value))
                    this.setLayout(arr)
                    data.value = JSON.stringify(arr)
                    this.$axios.post(url,data).then((res) => {
                        if(res.data.code == 0) {
                        // this.$message.success(res.data.msg)
                        // this.dialogVisible = false
                        // this.$store.commit("setisRequestPanelInfo", true)
                        this.visible = false
                        this.$message.success(res.data.msg)
                        this.$store.commit("setisRequestPanelInfo", true)
                        }


                    }).catch((err) => {});    
                }else{
                const url = Config.dev_url + "/api-apps/panel/updata";
                var data = {
                    id:this.allwidget.id,
                    value: []
                }

                var arr = JSON.parse(JSON.stringify(this.allwidget.value))
                this.setLayout(arr)
                data.value = JSON.stringify(arr)
                this.$axios.post(url,data ).then((res) => {
                    if(res.data.code == 0) {
                        this.visible = false
                        this.$message.success(res.data.msg)
                        this.$store.commit("setisRequestPanelInfo", true)
                    }else {
                        this.$message.warning(res.data.msg)
                    }
                })
                .catch((err) => {});
                }
                this.tableList.forEach(item=>item.isCheck = false)
            },
            /**
             * 设置页面布局
             * */
            setLayout: function(arr=[]) {
                var maxX = 12;//x轴长度
                var xAxis = new Array(maxX).fill(0);//横坐标分为12份
                var mIdx = 0
                if(arr.length>0) {
                    arr.forEach((layout,idx)=>{
                        layout.i = idx
                        var useArr = xAxis.slice(layout.x,layout.x+layout.w);//截取图形放置区域
                        var maxValue = Math.max(...useArr);//取最大值 即最大y值
                        for(let i= 0;i<layout.w;i++) {
                            xAxis[layout.x+i] = maxValue + layout.h;//区域 赋值
                        }
                        mIdx = idx
                    })
                }
                var checkList = this.tableList.filter(item=>item.isCheck)
                checkList.forEach((item,idx)=>{
                    var {layout} = item
                    var trueIdxList = [];//真实下标
                    var maxHList = [];//可放置区域 防止后 的 最大y

                    for(let i = 0;i<= maxX - layout.w;i++) {//循环放置 可放区域
                        var useArr = xAxis.slice(i,i+layout.w)
                        var maxValue = Math.max(...useArr)
                        maxHList.push(maxValue)
                        trueIdxList.push(i)
                    }
                    var minNum = Math.min(...maxHList);//取 放置后 最矮的
                    var xIdx = maxHList.indexOf(minNum);//取下标
                    var startX = trueIdxList[xIdx]
                    var startY = minNum

                    var obj = {
                        i: mIdx + idx,
                        x: startX,//x坐标
                        y: startY,//y坐标
                        Draggable: true,//是否可移动
                        isResizable: true,//是否可拖拽大小
                    }
                    mIdx++
                    Object.assign(layout,obj)
                    arr.push(layout)

                    for(let i = 0;i<layout.w;i++) {//区域 赋值
                        var value = minNum + layout.h
                        xAxis[startX + i] = value
                    }
                    //console.log(xAxis,"--------xAxis",layout.cname,layout.w,layout.h)
                })

            },
            onSearch(value) {
                //console.log(value,"vaaa",this.searchValue)
            },

            checkChoose(item) {
          
                item.isCheck = !Boolean(item.isCheck)
                this.$forceUpdate()
            },

        },
        mounted() {
            
            //console.log(this.$store.state.currentSelectDeptInfo.DEPTTYPE)
        }
    }
</script>

<style  lang="less">
.storeBody {
    display: flex;
    position: relative;
    .titleTab{
        // position: absolute;
        // top: -6%;
        // left: 40%;
        // width: 400px;
        // margin: auto;
        margin-top: 10px;
        display: flex;
        div{
            width: fit-content;
            padding-left: 5px;
            padding-right: 5px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            color: #595959;
            background: #F5F5F5;
        }
        .d_active{
            background: #dfe7fb;
            border-radius: 5px;
            color: #3366ff;
        }
    }
    .asideList {
        width: 250px;
        padding: 12px;
        padding-top: 0;
        box-sizing: border-box;
        .asideSearch {
            margin: 12px 0;
        }
        ul {
            height: 513px;
            overflow-y: auto;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                height: 36px;
                background: rgba(255,255,255,0.00);

                font-size: 14px;
                font-weight: 400;
                color: #202328;
                line-height: 22px;
                text-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                cursor: pointer;
                margin-bottom: 8px;
                i {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
                .typecount {
                    color: #646a74;
                }
            }
            .c_active {
                background: #dfe7fb;
                border-radius: 3px;
                // box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                div:nth-child(1) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #3366ff;
                    line-height: 22px;
                    text-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                }
            }
        }
    }
    .bodyContent {
        flex: 1;
        overflow: hidden;
        ul {
            height: 545px;
            overflow-y: auto;

            margin-top: 21px;
            margin-left: 12px;
            margin-right: 24px;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 120px;
                background: #ffffff;
                border-radius: 4px;
                margin-bottom: 10px;
                padding: 20px 12px 18px 20px;
                cursor: pointer;
                .item-left {
                    display: flex;
                    .item-micro {
                        width: 120px;
                        height: 82px;
                        background: #ffffff;
                        border: 1.26px solid #ebf0fc;
                        border-radius: 2px;
                        box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                        margin-right: 24px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        span {
                            display: block;
                            width: 62px;
                            height: 62px;
                            margin: 10px;
                            text-align: center;
                            line-height: 62px;
                            background: #ffffff;
                            img {
                                width: inherit;
                                height: inherit;
                            }
                        }
                    }
                    .item-content {
                        div {
                            &:nth-child(1) {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                text-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                            }
                            &:nth-child(2) {
                                margin-top: 4px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                                text-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                            }
                            &:nth-child(3) {
                                margin-top: 10px;
                                span {
                                    cursor: default;
                                    font-size: 12px;
                                    font-weight: 600;
                                    text-align: center;
                                    color: #595959;
                                    background: #F5F5F5;
                                    margin-right: 8px;
                                    border-radius: 2px;
                                    padding: 4px 8px;
                                }
                            }
                        }
                    }
                }
                .item-check {
                    width: 16px;
                    height: 16px;
                    background: rgba(255,255,255,0.90);
                    border: 1px solid #8f959e;
                    border-radius: 9px;
                    box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                    cursor: pointer;
                }
                .isCheck {
                    border: 1px solid #1c74f1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        width: 8px;
                        height: 8px;
                        background: #1c74f1;
                        border-radius: 50%;
                        box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
                    }
                }

            }
        }

    }
}
</style>
