<template>
  <div class="Awarp" style="height: 560px;display:flex;justify-content: space-between;">

      <div style="width: 1100px;height: 558px;position: relative;text-align: center;overflow-y: auto;">
        <img id="canvas_img" style="width:100%;" @click="clickimg($event)"  src="" @contextmenu.prevent="rightClick">
        <img v-for="(item,index) in clicklist" @mousedown="down(index,$event)" @contextmenu.prevent="rightClick"   @mouseenter="dianenter(item,index)" @mouseout="dianout"  :key="index" class="dian" src="@/assets/imgs/dian.png" :style="{top:item.y+'px',left:item.x+'px'}">
        <div class="tan" :style="{top:tan.y+'px',left:tan.x+'px'}" v-if="showtan" @mouseenter="dianenter2()" @mouseout="dianout2">
            <div style="margin-left: 10px;margin-top: 10px;margin-bottom: 14px;font-size: 15px;font-weight: 600;">选择设备</div>
            <el-select v-model="searchObj['ITEM']" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
              <el-option
                v-for="item in itemList"
                :key="item.assetNumber"
                :label="item.assetName"
                :value="item.assetNumber">
              </el-option>
            </el-select>           
            <div style="margin-top: 24px;display: flex;justify-content: flex-end;">
              <div class="lxhbtn" type="primary" style="background:#f78989" @click="cancel">删除</div>
              <div class="lxhbtn" type="primary" @click="bindasset" style="background:rgb(51, 102, 255)">确定</div>
            </div>
        </div>
      </div>
      <div style="width:calc(100% - 1100px);position: relative;border: 1px solid #d4d1d1;padding: 15px;">
        <div style="margin-top: 5px;font-weight: 600;">图片列表</div>
        <div style="margin-top: 12px;margin-bottom:20px;display: flex;justify-content: flex-end;">
          <el-button size="mini" type="primary" @click="uploadHeadimg" style="background:rgb(51, 102, 255)">上传图片</el-button>
          
        </div>
        <el-table
        :data="tableData"
        @row-click="clickimgrow"
        style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand" label-width="70px">
              <el-row :gutter="20" style="    padding-left: 20px;">
                <el-form-item label="图片名称">
                  <el-input
                    placeholder="请输入内容"
                    v-model="props.row.name"
                    clearable>
                  </el-input>
                </el-form-item>
              </el-row>
              <el-row :gutter="20" style="    padding-left: 20px;">
                <el-form-item label="描述">
                  <el-input
                    type="textarea"
                    style="width: 400px;"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="props.row.des"
                    clearable>
                  </el-input>
                </el-form-item>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="图片名称"
          prop="name">
        </el-table-column>
        <el-table-column
          label="状态"
          prop="imgstatus">
        </el-table-column>
        <el-table-column
          label="上传时间"
          prop="CREATETIME">
        </el-table-column>
        <el-table-column label="操作" width=230>
          
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">激活</el-button>
            <el-button
              size="mini"
              @click="save(scope.$index, scope.row)">保存</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="delimg(scope.$index,scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      </div>
  </div>
</template>
<script>
import Img001 from "@/assets/imgs/add/product1.png"
import { jsonlint } from '@/json'
import { getRealJsonData } from '@/big'
export default {
  name: 'lvscBox20',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    show: {
      type: Boolean,
    },
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6}
      }
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    show: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getimglist()
          this.getList()
        })
      },
      deep: true,
      immediate: true
    },
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getOperateWidth()
        })
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      form:{},
      tableData: [],
      activeName: 'first',
      tan:{
        x:'',
        y:''
      },
      showtan:false,
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中
      clicklist:[],
      itemList: [
        {
          assetNumber: '1AT1_抽屉柜',
          assetName: '1AT1_抽屉柜'
        },
        {
          assetNumber: '1AT2_抽屉柜',
          assetName: '1AT2_抽屉柜'
        },
        {
          assetNumber: '1AT3_抽屉柜',
          assetName: '1AT3_抽屉柜'
        }
      ],
      columns: [
        {title: '型号', value1: 'value1'},
        {title: '上次维护时间', value1: 'value2', value2: 'value3'},
        {title: '额定电压', value1: 'value4', value2: 'value5'},
        {title: '额定电流', value1: 'value6', value2: 'value7',},
        {title: '额定频率', value1: 'value8', value2: 'value9'},
      ],
      searchObj: {
        ITEM: '',//选中的项目
      },
      detailInfo: {},
      isMouseDown: false,
      originX: 0,
      originY: 0,
      lastx:0,
      lasty:0,
      tanindex:0,
      imgmsg:"",
      imgid:"",
      showtanimg:false,
      nowdata:{},
      tableindex:0
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
      
    }
  },
  methods: {
    debounce(func, delay){
      //定义初始时间(开始触发事件的时间)
      var start = 0;
      return function(){
        var that = this;
        var args = arguments;
        //获取当前时间戳
        var current = Date.now();
        // 判断当前时间与初始时间是否超过间隔
        if(current - start >= delay){
          //执行事件处理程序
          func.call(that, args)
          //更新初始时间
          start = current;
        }
      }
    },
    save(a,b){
      console.log(b)
      let data = b.main
      let FLAGIMAGE = JSON.parse(b.main.FLAGIMAGE)
      FLAGIMAGE.name = b.name
      FLAGIMAGE.des = b.des
      FLAGIMAGE.imgstatus = b.imgstatus
      data.FLAGIMAGE = JSON.stringify(FLAGIMAGE)
     
      console.log(data)
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE",
        method: "put",
        data: data
      }).then(res => {
        let obj = JSON.parse(JSON.stringify(JSON.parse(res.data.data.FLAGIMAGE)))
        obj['CREATETIME'] = res.data.data.CREATETIME
        obj['id']= obj.imgid
        obj['imgid']= res.data.data.ID
        obj['main']= res.data.data
        this.$set(this.tableData,this.tableindex,obj)
        this.$message.success('保存成功')
      })
    },
    cancel(){
      
      this.clicklist.splice(this.tanindex, 1)
      this.showtan = false
    },
    blobToDataURL(blob, callback) {
          var a = new FileReader();
          a.onload = function(e) {
              callback(e.target.result);
          }
          a.readAsDataURL(blob);
          return a;
      },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'image/png' }) // 必须指定type类型
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    formatByte2Img(data) {
      let blobUrl = ''
      const bytes = new Uint8Array(data)
      const blob = new Blob([bytes], { type: 'image/png' })
      blobUrl = (window.URL || window.webkitURL).createObjectURL(blob)
      return blobUrl
      },
    clickimgrow(row, column, cell, event){
      console.log(this.tableData)
      console.log(row)
      for(let index in this.tableData){
        if(this.tableData[index].id == row.id){
          this.tableindex = index
        }
      }
      
      if(row.id){
        this.imgid = row.id
        console.log(row)
        
        this.clicklist = JSON.parse(row.main.FLAGIMAGE).clicklist ? JSON.parse(row.main.FLAGIMAGE).clicklist :[]

        this.$axios({
          url: Config.dev_url + "/api-file/file/download?fid="+this.imgid,
          method: "get",
          responseType: "blob",
        }).then(res => {
    
          let canvas_img = document.querySelector('#canvas_img');
          console.log(res)
          const blob = new Blob([res.data], { type: 'image/png' })
          const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob)
          canvas_img.src = imageUrl;
          // canvas_img.onload = function(e) {
          //     window.URL.revokeObjectURL(canvas_img.src);
          // };

          // this.getBase64(res.data).then((base64) => {
              
          //     this.imgsrc = base64
          //     console.log('base64', this.imgsrc)
          //   })
        })
      }
      
    },
    delimg(index,item){
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE/reomveByIds?ids="+item.imgid,
        method: "delete",
      }).then(res => {
        this.$message.success('删除成功')
        this.getimglist()
      })
    },
    getimglist(){
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE/list",
        method: "post",
        data: {
          limit: 20,
          p_order: "[]",
          p_query: "{\"links\":[{\"key\":\"DCODE\",\"links\":[],\"type\":\"Like\",\"value\":\""+this.currentSelectDeptInfo.CODE+"\"}],\"type\":\"LinkAnd\"}",
          page: 1
        }
      }).then(res => {
        let arr = []
        for(let item of res.data.data){
          let obj = JSON.parse(JSON.stringify(JSON.parse(item.FLAGIMAGE)))
          obj['CREATETIME'] = item.CREATETIME
          obj['id']= obj.imgid
          obj['imgid']= item.ID
          obj['main']= item
          arr.push(obj)
        }
        console
        this.tableData = arr
      })
    },
    addimg(){
      
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE",
        method: "post",
        data: {
          DCODE: this.currentSelectDeptInfo.CODE,
          FLAGIMAGE: this.imgmsg,
        }
      }).then(res => {
        this.getimglist()
      })
    },
    // 上传
    uploadHeadimg(files){
        console.log("点击了",Object.prototype.toString.call(files))
        if(files == '[object PointerEvent]' || files ==  '[object MouseEvent]') { //点击事件时执行
          this.fileUpload(this.uploadHeadimg)
          return
        }
        if(files == '[object FileList]') { //选择文件后返回

        var formData = new FormData();
        formData.append("fileList", files[0]);
        formData.append("appkey", "WORKORDER");
        formData.append("recordid", "1635320176178049026");
        var config = {
          //添加请求头
          headers: { "Content-Type": "multipart/form-data",},
          transformResponse: [response => JSON.parse(response.replace(/("fileid":)(\d{0,})(,)/g, '$1'+'"'+'$2'+'"'+'$3'))],
          // transformResponse: [response => JSON.parse(response.replace(/(?:"fileid":)(\d{0,})/g, '"$1"'))],
        }
        const url = Config.dev_url + "/api-file/file/uploads";
        this.$axios.post(url, formData, config,).then((res) => {
                if(res.data.code==0){
                    // let aa = res.data.data[0].fileid.replace(/("fileid":)(\d{0,})(,)/g, '$1'+'"'+'$2'+'"'+'$3')
                    // console.log(aa)
                    this.$message.success(res.data.msg)
                    let obj = {}
                    console.log(res)
                    obj["imgid"] = res.data.data[0].fileid
                    obj["downid"] = res.data.data[0].fileid
                    obj["name"] = ""
                    obj["des"] = ""
                    
                    obj["imgstatus"] = "未激活"
                   
                    this.imgmsg = JSON.stringify(obj)
                    this.addimg()

                }else{
                    // this.$message.warning(res.data.msg)
                }
            })
        }
    },
    // 上传
    fileUpload(callBack) {
        var iElement = document.createElement('input');
        iElement.type = 'file';
        iElement.name = 'file';
        iElement.value = '';
        iElement.onchange = (e=>{
            const flies = iElement.files;
            const value = iElement.value;
            if(this.isUpload(value)) {
                return callBack(flies)
            }
        })
        iElement.click()
    },
    isUpload: function (imgPath) {
      if (this.isImg(this.getUploadUrlSuffix(imgPath))) {
        return true;
      } else {
        this.$message.warning("只能上传图片格式的(jpg|jpeg|png)");
        return false;
      }
    },
    /**
     * @description 是否为图片 目前仅支持 jpg jpeg png
     * @param {*} str 文件类型
     */
    isImg: function (str) {
      if (str.search("[.]+(jpg|jpeg|png|JPG|JPEG|PNG)$")) {
        return false;
      }
      return true;
    },
    /**
     * @description 截取url后面的文件类型
     * @param {*} urlStr 文件名
     */
    getUploadUrlSuffix: function(urlStr){
        var url = urlStr.substring(urlStr.lastIndexOf("\."),urlStr.length);
        return url;
    },
    getmm(num=16) {
      var amm = [1,2,3,4,5,6,7,8,9];
      var tmp = Math.floor(Math.random() * num);
      var s = tmp;
      s = s + amm[tmp];
      for (let i = 0; i < Math.floor(num/2)-1; i++) {
        tmp = Math.floor(Math.random() * 26);
        s = s + String.fromCharCode(65 + tmp);
      }
      for (let i = 0; i < (num-Math.floor(num/2)-1); i++) {
        tmp = Math.floor(Math.random() * 26);
        s = s + String.fromCharCode(97 + tmp);
      }
      return s;
    },
    handleDelete(){},
    handleEdit(a,b){
      console.log(a)
      for(let index in this.tableData){
        if(index == a){
          this.tableData[index].imgstatus = '激活'
        }else{
          this.tableData[index].imgstatus = '未激活'
        }


        console.log(b)
        let data = this.tableData[index].main
        let FLAGIMAGE = JSON.parse(this.tableData[index].main.FLAGIMAGE)
        FLAGIMAGE.name = this.tableData[index].name
        FLAGIMAGE.des = this.tableData[index].des
        FLAGIMAGE.imgstatus = this.tableData[index].imgstatus
        data.FLAGIMAGE = JSON.stringify(FLAGIMAGE)

        this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE",
        method: "put",
        data: data
        }).then(res => {
          let obj = JSON.parse(JSON.stringify(JSON.parse(res.data.data.FLAGIMAGE)))
          obj['CREATETIME'] = res.data.data.CREATETIME
          obj['id']= obj.imgid
          obj['imgid']= res.data.data.ID
          obj['main']= res.data.data
          // this.$set(this.tableData,this.tableindex,obj)
          
        })
      }
      this.$message.success('激活成功')
      // console.log(b)
      // let data = b.main
      // let FLAGIMAGE = JSON.parse(b.main.FLAGIMAGE)
      // FLAGIMAGE.name = b.name
      // FLAGIMAGE.des = b.des
      // FLAGIMAGE.imgstatus = '激活'
      // data.FLAGIMAGE = JSON.stringify(FLAGIMAGE)
      
      

      console.log(data)
      
    },
    bindasset(){
      let obj = {
        x:this.clicklist[this.tanindex].x,
        y:this.clicklist[this.tanindex].y,
        asset:this.searchObj['ITEM']
      }
      this.$set(this.clicklist,this.tanindex,obj)
      let item = JSON.parse(this.tableData[this.tableindex].main.FLAGIMAGE)
      item["clicklist"] = this.clicklist
      this.tableData[this.tableindex].main.FLAGIMAGE = JSON.stringify(item)
      
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE",
        method: "put",
        data: this.tableData[this.tableindex].main
      }).then(res => {
        this.$message.success('保存成功')
        let obj = JSON.parse(JSON.stringify(JSON.parse(res.data.data.FLAGIMAGE)))
        obj['CREATETIME'] = res.data.data.CREATETIME
        obj['id']= obj.imgid
        obj['imgid']= res.data.data.ID
        obj['main']= res.data.data
        this.$set(this.tableData,this.tableindex,obj)
      })

      this.showtan = false
    },
    down(index,e){
      
      // 获取拖拽起始位置坐标
      this.originX = e.clientX || e.touches[0].clientX;
      this.originY = e.clientY || e.touches[0].clientY;
      this.isMouseDown = true;
      document.onmousemove = (ev) => {
        if (!this.isMouseDown) return false;
        const w = this.width;
        // 获取拖拽移动的距离
        const eventX = ev.clientX || ev.touches[0].clientX;
        const eventY = ev.clientY || ev.touches[0].clientY;
        const moveX = eventX - this.originX;
        const moveY = eventY - this.originY;
        
        console.log(moveX+'x')
        console.log(moveY+'y')
        this.lastx = moveX
        this.lasty = moveY
      };
      document.onmouseup = (ev) => {
       
         document.onmousemove=null;
         let obj = {
          x:this.clicklist[index].x+this.lastx,
          y:this.lasty+this.clicklist[index].y,
          asset:this.clicklist[index].asset?this.clicklist[index].asset:""
        }
        console.log(obj)

        this.$set(this.clicklist,index,obj)
        document.onmouseup=null;
        this.bindasset()
      };
      document.ondragstart = function(ev) {
        ev.preventDefault();
      };
      document.ondragend = function(ev) {
        ev.preventDefault();
      };
      },
    chengeshowtan(){
      let vm = this
      
       
      if(vm.showtanimg == false){
        vm.showtan = false
        console.log(111111111)
      }
          
      
    },
    rightClick(){
        this.showtan = false
    },
    dianout(){
      let vm = this
      console.log(1)
      vm.showtanimg = false
      this.debounce(this.chengeshowtan,500)
      // setTimeout(() => {
        
      //       if(vm.showtanimg == false){
      //         vm.showtan = false
      //         console.log(111111111)
      //       }
            
      //   }, 1500);
      
    },
    dianout2(){
      console.log(2)
      // this.showtan = false
      this.showtanimg =false
    },

    dianenter(item,index){
      if(item.x+160+300>1100){
            this.$set(this.tan,"x",item.x-240)
      }else{
        this.$set(this.tan,"x",item.x+50)
      }
      this.tanindex = index
      this.$set(this.searchObj,'ITEM',item.asset)
      
      this.$set(this.tan,"y",item.y)
      this.showtan = true
    },
    dianenter2(item,index){
      console.log(this.itemList)
      this.showtanimg =true
      this.showtan = true
    },
    clickimg(event){
      console.log(this.itemList)
      console.log(event)
      let obj = {
        x:event.offsetX-30,
        y:event.offsetY-30
      }
      this.clicklist.push(obj)
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type:"电表"
        },
      }).then(res => {
        if(!res.data.data){
          this.itemList = []
        }else{
        this.itemList = res.data.data
        }
        console.log(this.itemList)
      })
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if (!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if (!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    // 切换项目
    handleChange(value) {
      
    },
    // 获取数据
    getData() {
      this.$axios({
        url: Config.dev_url + "/api-apps/lowVoltage/realtimeValue",
        method: "post",
        data: {
          assetNumber: this.searchObj.ITEM,
        }
      }).then(res => {
        this.isLoading = true
        let result = res.data.data
        if(result.length === 0){
          this.detailInfo = {
            productSrc: Img001,
            status: "",
          }
          this.detailInfo["list"] = result
          this.isLoading = false
          return
        }
        let index = result.findIndex(item => {
          return item.name === "工作状态"
        })
        this.detailInfo = {
          productSrc: Img001,
          status: result[index].num
        }
        result.splice(index,1)
        this.detailInfo["list"] = result
        console.log(this.detailInfo)
        this.isLoading = false
        console.log(res)
      })

    },
    getData0() {
      this.isLoading = true
      this.detailInfo = {
        value1: "ABB_3P 63A-400A",
        value2: "128",
        value3: "h",
        value4: "400",
        value5: "V",
        value6: "1000",
        value7: "A",
        value8: "2780",
        value9: "kWh",


        value10: "380V",
        value11: "200kW",
        value12: "80℃",
        productSrc: Img001
      }
      this.isLoading = false
    },
  },
  created(){
    
  },
  mounted() {
    
    this.clicklist= [],
    this.itemList=[]
    this.getOperateWidth()
    
      // this.getData()
    
  }
}
</script>

<style lang="less" scoped>
.lvscBox10 {
  .card-content1 {
    display: flex;

    .product-pre {
      width: 362px;
      height: 336px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .product-info {
      flex: 1;

      .product-status {
        div {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
          }

          &:nth-child(2) {
            margin-top: 6px;
            margin-bottom: 16px;

            font-size: 36px;
            font-weight: 500;
            color: #3366ff;
            line-height: 48px;
          }
        }
      }

      .product-info-item {
        margin-bottom: 24px;

        .product-info-item-top {
          display: flex;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 20px;
        }

        .product-info-item-progress {
          width: 100%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 14px;
          margin-bottom: 9px;

          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51A34B;
          }
        }

        .product-info-item-bottom {
          display: flex;
          justify-content: space-between;

          font-size: 14px;
          font-weight: 400;
          color: #696969;
          line-height: 16px;
        }
      }

    }

  }

  /deep/ .ant-descriptions-bordered {
    .ant-descriptions-item-label {
      background: transparent;
      width: 362px;
    }

    .ant-descriptions-item-content {
      background: #F5F5F5;
      display: flex;
      justify-content: space-between;
    }
  }
}

.dian{
    position: absolute;
    width: 30px;
    
}
.tan{
  background: white;
  border-radius: 9px;
  position: absolute;
  width: 230px;
  height: 151px;
  background: rgba(255, 255, 255,1);
  color:#454545;
  padding:10px;
}

.group{
    display: flex;
    margin-top: 15px;
    margin-left:10px;
    align-items: center;
    font-size: 12px;
}
</style>
