<template>
  <div id="dragBoard">
    <div>
      <grid-layout
          :col-num="12"
          :style="{position:'relative',overflowX: 'hidden',top:'-10px'}"
          :is-draggable="draggable"
          :is-resizable="resizable"
          :layout="layout"
          :responsive="responsive"
          :row-height="60"
          :use-css-transforms="true"
          :vertical-compact="true"
          @layout-updated="layoutUpdatedEvent"
      >
        <grid-item v-for="(item, indexVar) in layout"
                   :key="indexVar"
                   :h="item.h"
                   :i="item.i"
                   :minW="item.minw"
                   :minH="item.minh"
                   :maxW="item.maxw"
                   :maxH="item.maxh"
                   :static="item.static"
                   :w="item.w"
                   :x="item.x"
                   :y="item.y"
                   :isDraggable="item.Draggable"
                   :isResizable="item.isResizable"
                   drag-allow-from=".vue-draggable-handle"
                   drag-ignore-from=".no-drag"
        >
          <template v-if="!!item.component">
            <component :is="gatherBox.indexOf(item.component) != -1? item.component : 'emptyBox'" :gridOption="item"/>
          </template>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import {GridLayout, GridItem} from 'vue-grid-layout'

export default {
  name: "dragBoard",
  components: {
    GridLayout,
    GridItem,
    emptyBox: () => import('@/component/chartStore/charFiles/emptyBox.vue'),//404Widget
    // 碳管理W*
    carbonBox1: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox1.vue'),//W01-碳排放结构
    carbonBox4: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox4.vue'),//W04-碳排放KPI
    carbonBox6: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox6.vue'),//W06-碳排放分析
    carbonBox7: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox7.vue'),//W07-碳排放输入
    carbonBox8: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox8.vue'),//W08-碳排放总览
    carbonBox9: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox9.vue'),//W08-碳排放强度总览
    carbonBox10: () => import('@/component/chartStore/charFiles/carbonCom/carbonBox10.vue'),//W10-碳排放结构

    // 能源管理 WO**
    powerBox1: () => import('@/component/chartStore/charFiles/powerCom/powerBox1.vue'),//W01：能源结构
    powerBox2: () => import('@/component/chartStore/charFiles/powerCom/powerBox2.vue'),//W02：能源KPI排名(公司)
    powerBox3: () => import('@/component/chartStore/charFiles/powerCom/powerBox3.vue'),//W03-电能分项
    powerBox4: () => import('@/component/chartStore/charFiles/powerCom/powerBox4.vue'),//W04-负载空间
    powerBox5: () => import('@/component/chartStore/charFiles/powerCom/powerBox5.vue'),//W05-能耗热力图
    powerBox6: () => import('@/component/chartStore/charFiles/powerCom/powerBox6.vue'),//W06-能源流向
    powerBox7: () => import('@/component/chartStore/charFiles/powerCom/powerBox7.vue'),//能源质量-W01：质量总览
    powerBox8: () => import('@/component/chartStore/charFiles/powerCom/powerBox8.vue'),//W03-功率监测
    powerBox9: () => import('@/component/chartStore/charFiles/powerCom/powerBox9.vue'),//W03-功率监测
    powerBox10: () => import('@/component/chartStore/charFiles/powerCom/powerBox10.vue'),//W05-电压合格率
    powerBox11: () => import('@/component/chartStore/charFiles/powerCom/powerBox11.vue'),//W02-电压畸变率
    powerBox12: () => import('@/component/chartStore/charFiles/powerCom/powerBox12.vue'),//W03-功率监测
    powerBox13: () => import('@/component/chartStore/charFiles/powerCom/powerBox13.vue'),//W01-需量分析
    powerBox14: () => import('@/component/chartStore/charFiles/powerCom/powerBox14.vue'),//W02-购电分析
    powerBox15: () => import('@/component/chartStore/charFiles/powerCom/powerBox15.vue'),//W03-重载分析
    powerBox16: () => import('@/component/chartStore/charFiles/powerCom/powerBox16.vue'),//W04-绿电替代率
    powerBox17: () => import('@/component/chartStore/charFiles/powerCom/powerBox17.vue'),//W05-电率分析
    powerBox18: () => import('@/component/chartStore/charFiles/powerCom/powerBox18.vue'),//W06-电费分析
    powerBox19: () => import('@/component/chartStore/charFiles/powerCom/powerBox19.vue'),//W07-电度电费分析
    powerBox20: () => import('@/component/chartStore/charFiles/powerCom/powerBox20.vue'),//W08-综合能源收益
    powerBox21: () => import('@/component/chartStore/charFiles/powerCom/powerBox21.vue'),//D08：当前电能费用
    powerBox22: () => import('@/component/chartStore/charFiles/powerCom/powerBox22.vue'),//D09：度电成本
    powerBox23: () => import('@/component/chartStore/charFiles/powerCom/powerBox23.vue'),//D10：事件统计
    powerBox24: () => import('@/component/chartStore/charFiles/powerCom/powerBox24.vue'),//D11：事件统计
    powerBox25: () => import('@/component/chartStore/charFiles/powerCom/powerBox25.vue'),//W04-绿电消纳率
    powerBox26: () => import('@/component/chartStore/charFiles/powerCom/powerBox26.vue'),//W04-电流畸变率
    powerBox27: () => import('@/component/chartStore/charFiles/powerCom/powerBox27.vue'),//W04-能源KPI排名(区域)
    powerBox28: () => import('@/component/chartStore/charFiles/powerCom/powerBox28.vue'),//W04-能源KPI排名(部门)
    powerBox29: () => import('@/component/chartStore/charFiles/powerCom/powerBox29.vue'),//W07-电度电费分析
    // 低压开关柜 LP**
    lvscBox1: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox1.vue'),//LP01-配电室环境
    lvscBox2: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox2.vue'),//LP02-当前告警
    lvscBox3: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox3.vue'),//LP03-设备在线状态
    lvscBox4: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox4.vue'),//LP04-网关连接状态
    lvscBox5: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox5.vue'),//LP05-设备利用率
    lvscBox6: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox6.vue'),//LP06-负载率
    lvscBox7: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox7.vue'),//LP07-低压配电系统电参量
    lvscBox8: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox8.vue'),//LP08-最新事件
    lvscBox9: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox9.vue'),//LP010-开关柜基础数据
    lvscBox10: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox10.vue'),//LP14-抽屉基础信息
    lvscBox11: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox11.vue'),//LP15-抽屉电量参数
    lvscBox12: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox12.vue'),//LP13-设备健康评分
    lvscBox13: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox13.vue'),//LP09-设备健康状态
    lvscBox14: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox14.vue'),//LP17-断路器基础信息
    lvscBox15: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox15.vue'),//LP12-设备利用率
    lvscBox16: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox16.vue'),//LP16-抽屉趋势图
    lvscBox17: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox17.vue'),//LP11-开关柜电参量
    lvscBox18: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox18.vue'),//LP019-设备利用率
    lvscBox19: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox19.vue'),//LP020-功率利用率
    lvscBox20: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox20.vue'),//LP020-断路器运行参数

    lvscBox21: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox21.vue'),//LP020-断路器运行参数
    lvscBox22: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox22.vue'),//配电设备BI状态
    lvscBox23: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox23.vue'),//变压器监测
    lvscBox24: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox24.vue'),//变压器运行曲线
    lvscBox25: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox25.vue'),//设备连接状态
    // 柴油发电机DG**
    dieselgeneratorBox1: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox1.vue'),//DG01-发电机基础信息
    dieselgeneratorBox2: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox2.vue'),//DG02-发电机报警统计
    dieselgeneratorBox3: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox3.vue'),//DG03-发电机运行时间统计
    dieselgeneratorBox4: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox4.vue'),//DG04-发电机发电量统计
    dieselgeneratorBox5: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox5.vue'),//DG05-发电机平均百度电油耗统计
    dieselgeneratorBox6: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox6.vue'),//DG06-发电机负荷时间统计
    dieselgeneratorBox7: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox7.vue'),//DG07-发电机运行时间
    dieselgeneratorBox8: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox8.vue'),//DG08-发电机发电量排名
    dieselgeneratorBox9: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox9.vue'),//DG09-发电机百度电油耗
    dieselgeneratorBox10: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox10.vue'),//DG10-多台发电机报警对比统计
    dieselgeneratorBox11: () => import('@/component/chartStore/charFiles/dieselgeneratorCom/dieselgeneratorBox11.vue'),//DG11-发电机功率利用率
    // 光伏KPI视图-L**
    photovoltaicBox1: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox1.vue'),//L01-电站状态
    photovoltaicBox2: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox2.vue'),//L02-光伏电站状态
    photovoltaicBox3: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox3.vue'),//L03-当前告警
    photovoltaicBox4: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox4.vue'),//L04-气象信息
    photovoltaicBox5: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox5.vue'),//L05-光伏并网电站列表
    photovoltaicBox6: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox6.vue'),//L06：电站发电量统计
    photovoltaicBox7: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox7.vue'),//L07-发电量排名
    photovoltaicBox8: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox8.vue'),//L08：等效利用小时数
    photovoltaicBox9: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox9.vue'),//L09-设备利用率
    photovoltaicBox10: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox10.vue'),//L10-光伏电站逻辑视图
    photovoltaicBox11: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox11.vue'),//L11-光伏逆变器实时数据
    photovoltaicBox12: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox12.vue'),//L12-设备台账
    photovoltaicBox13: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox13.vue'),//L13-光伏收益
    photovoltaicBox14: () => import('@/component/chartStore/charFiles/photovoltaicCom/photovoltaicBox14.vue'),//L14-光伏运行曲线

    // ABB_homepage  能源看板
    energykanbanBox1: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox1.vue'),//D01：地图
    energykanbanBox2: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox2.vue'),//D02：当前碳排放总览
    energykanbanBox3: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox3.vue'),//D03-设备总数
    energykanbanBox4: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox4.vue'),//D04：当前工单总览
    energykanbanBox5: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox5.vue'),//D05：当前总能耗
    energykanbanBox6: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox6.vue'),//D05：当前总能耗
    energykanbanBox7: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox7.vue'),//D06：总能耗详情
    energykanbanBox8: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox8.vue'),//D07：电量使用排名
    energykanbanBox9: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox9.vue'),//D07：电量使用排行榜
    energykanbanBox10: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox10.vue'),//D07：电量使用排行榜(钱)
    energykanbanBox11: () => import('@/component/chartStore/charFiles/energykanbanCom/energykanbanBox11.vue'),//装机容量

    // CP09-充电桩运行曲线
    cdzBox1: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox1.vue'),//CP01-充电桩总览
    cdzBox2: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox2.vue'),//CP02-今日总览
    cdzBox3: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox3.vue'),//CP03-充电桩状态
    cdzBox4: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox4.vue'),//CP04-使用情况
    cdzBox5: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox5.vue'),//CP05-站点排名
    cdzBox6: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox6.vue'),//CP06-设备利用率
    cdzBox7: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox7.vue'),//CP07-功率利用率
    cdzBox8: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox8.vue'),//CP08-充电桩实时数据
    cdzBox9: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox9.vue'),//CP09-三相不平衡
    cdzBox10: () => import('@/component/chartStore/charFiles/cdzCom/cdzBox10.vue'),//CP10-功率监测

    // 储能
    chuneng1: () => import('@/component/chartStore/charFiles/chuneng/chuneng1.vue'),//PCS充电量排名
    chuneng2: () => import('@/component/chartStore/charFiles/chuneng/chuneng2.vue'),//充电量统计
    chuneng3: () => import('@/component/chartStore/charFiles/chuneng/chuneng3.vue'),//储能实时数据
    chuneng4: () => import('@/component/chartStore/charFiles/chuneng/chuneng4.vue'),//电池实时数据
    chuneng5: () => import('@/component/chartStore/charFiles/chuneng/chuneng5.vue'),//电池信息
    chuneng6: () => import('@/component/chartStore/charFiles/chuneng/chuneng6.vue'),//pcs运行曲线
    chuneng7: () => import('@/component/chartStore/charFiles/chuneng/chuneng7.vue'),//bms运行曲线
    chuneng8: () => import('@/component/chartStore/charFiles/chuneng/chuneng8.vue'),//电池簇电压
    chuneng9: () => import('@/component/chartStore/charFiles/chuneng/chuneng9.vue'),//电池簇温度
    chuneng10: () => import('@/component/chartStore/charFiles/chuneng/chuneng10.vue'),//电池簇电流
    chuneng11: () => import('@/component/chartStore/charFiles/chuneng/chuneng11.vue'),//电池簇总览
    chuneng12: () => import('@/component/chartStore/charFiles/chuneng/chuneng12.vue'),//电池簇总览
    chuneng13: () => import('@/component/chartStore/charFiles/chuneng/chuneng13.vue'),//电池簇总览
    chuneng14: () => import('@/component/chartStore/charFiles/chuneng/chuneng14.vue'),//电池簇总览
    chuneng15: () => import('@/component/chartStore/charFiles/chuneng/chuneng15.vue'),//电池簇总览
    chuneng16: () => import('@/component/chartStore/charFiles/chuneng/chuneng16.vue'),//电池簇总览
    chuneng17: () => import('@/component/chartStore/charFiles/chuneng/chuneng17.vue'),//电池簇总览
    chuneng18: () => import('@/component/chartStore/charFiles/chuneng/chuneng18.vue'),//电池簇总览

    //运维管理
    operationAm1: () => import('@/component/chartStore/charFiles/operationAm/operationAm1.vue'),//工单总览
    operationAm2: () => import('@/component/chartStore/charFiles/operationAm/operationAm2.vue'),//工单量排行榜
    operationAm3: () => import('@/component/chartStore/charFiles/operationAm/operationAm3.vue'),//工单类型分析
    operationAm4: () => import('@/component/chartStore/charFiles/operationAm/operationAm4.vue'),//年工量统计
    operationAm5: () => import('@/component/chartStore/charFiles/operationAm/operationAm5.vue'),//年工量统计
    operationAm6: () => import('@/component/chartStore/charFiles/operationAm/operationAm6.vue'),//事件状态统计
    operationAm7: () => import('@/component/chartStore/charFiles/operationAm/operationAm7.vue'),//事件统计
  },
  computed: {
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo
    },
    allwidget() {
      return this.$store.state.allwidget
    },
    dash() {
      return this.$store.state.dashboardOptions
    },

  },
  props: {
    panellist: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  watch: {
    dash: {
      handler(newVal, oldVal) {

        this.chenge = false
      },
      deep: true
    },
    allwidget: {
      handler(newVal, oldVal) {
        var layout = newVal.value || []
        layout.forEach(item => {
          item.isResizable = false;//是否可拖拽大小
          //console.log(this.$store.state.currentSelectDeptInfo.DEPTTYPE)
          // if(this.$store.state.currentSelectDeptInfo.DEPTTYPE == '站点'&&item.cname == "逆变器设备利用率站点"){

          //     item.h = 5
          //     item.w = 3
          // }
        });
        this.layout = layout
        //console.log(layout)
        this.tabChange(newVal, oldVal)
      },
      deep: true
    },

  },
  data() {
    return {
      chenge: true,
      draggable: true,//可移动
      resizable: true,//可改变宽高
      responsive: true,//标识布局是否为响应式
      layout: [],
      isLoading: true,//加载中
      gatherBox: [//widget图集合 需与 components 同步更新
        "carbonBox1",
        "carbonBox4",
        "carbonBox6",
        "carbonBox7",
        "carbonBox8",
        "carbonBox9",
        "carbonBox10",
        "powerBox1",
        "powerBox2",
        "powerBox3",
        "powerBox4",
        "powerBox5",
        "powerBox6",
        "powerBox7",
        "powerBox8",
        "powerBox9",
        "powerBox10",
        "powerBox11",
        "powerBox12",
        "powerBox13",
        "powerBox14",
        "powerBox15",
        "powerBox16",
        "powerBox17",
        "powerBox18",
        "powerBox19",
        "powerBox20",
        "powerBox21",
        "powerBox22",
        "powerBox23",
        "powerBox24",
        "powerBox25",
        "powerBox26",
        "powerBox27",
        "powerBox28",
        "powerBox29",
        "lvscBox1",
        "lvscBox2",
        "lvscBox3",
        "lvscBox4",
        "lvscBox5",
        "lvscBox6",
        "lvscBox7",
        "lvscBox8",
        "lvscBox9",
        "lvscBox10",
        "lvscBox11",
        "lvscBox12",
        "lvscBox13",
        "lvscBox14",
        "lvscBox15",
        "lvscBox16",
        "lvscBox17",
        "lvscBox18",
        "lvscBox19",
        "lvscBox20",
        "lvscBox21",
        "lvscBox22",
        "lvscBox23",
        "lvscBox24",
        "lvscBox25",
        "dieselgeneratorBox1",
        "dieselgeneratorBox2",
        "dieselgeneratorBox3",
        "dieselgeneratorBox4",
        "dieselgeneratorBox5",
        "dieselgeneratorBox6",
        "dieselgeneratorBox7",
        "dieselgeneratorBox8",
        "dieselgeneratorBox9",
        "dieselgeneratorBox10",
        "dieselgeneratorBox11",
        "photovoltaicBox1",
        "photovoltaicBox2",
        "photovoltaicBox3",
        "photovoltaicBox4",
        "photovoltaicBox5",
        "photovoltaicBox6",
        "photovoltaicBox7",
        "photovoltaicBox8",
        "photovoltaicBox9",
        "photovoltaicBox10",
        "photovoltaicBox11",
        "photovoltaicBox12",
        "photovoltaicBox13",
        "photovoltaicBox14",
        "energykanbanBox1",
        "energykanbanBox2",
        "energykanbanBox3",
        "energykanbanBox4",
        "energykanbanBox5",
        "energykanbanBox6",
        "energykanbanBox7",
        "energykanbanBox8",
        "energykanbanBox9",
        "energykanbanBox10",
        "energykanbanBox11",
        "cdzBox1",
        "cdzBox2",
        "cdzBox3",
        "cdzBox4",
        "cdzBox5",
        "cdzBox6",
        "cdzBox7",
        "cdzBox8",
        "cdzBox9",
        "cdzBox10",
        "chuneng1",
        "chuneng2",
        "chuneng3",
        "chuneng4",
        "chuneng5",
        "chuneng6",
        "chuneng7",
        "chuneng8",
        "chuneng9",
        "chuneng10",
        "chuneng11",
        "chuneng12",
        "chuneng13",
        "chuneng14",
        "chuneng15",
        "chuneng16",
        "chuneng17",
        "chuneng18",
        "operationAm1",
        "operationAm2",
        "operationAm3",
        "operationAm4",
        "operationAm5",
        "operationAm6",
        "operationAm7"
      ]
    }
  },
  methods: {
    // 切换tab滚动条到顶部
    tabChange(newVal, oldVal) {
      if (!!oldVal && newVal.id != oldVal.id) {
        document.querySelector('#dragBoard').scrollTop = 0;
      }
    },
    getpanelInfo() {

    },
    layoutUpdatedEvent: function (newLayout) {

      if (!this.isLoading) {
        this.updateLayout()
      }
    },
    // 更新布局
    updateLayout() {

      if (!this.allwidget || Object.keys(this.allwidget).length == 0) {
        return
      }


      if (this.allwidget.id == 389 || this.allwidget.location == "old") {
        if (this.chenge) {
          const url = Config.dev_url + "/api-apps/panel/add";
          var data = {
            location: this.$store.state.currentSelectDeptInfo.FULLNAME,//当前机构名
            type: this.currentSelectMenuInfo.name,//当前菜单名称
            index: 0,
            name: '总览',
            main: '0',
            value: JSON.stringify(this.allwidget.value)
          }

          this.$axios.post(url, data).then((res) => {
            if (res.data.code == 0) {
              // this.$message.success(res.data.msg)
              // this.dialogVisible = false
              // this.$store.commit("setisRequestPanelInfo", true)
            }


          }).catch((err) => {
          });
          this.chenge = true
        }
      } else {
        const url = Config.dev_url + "/api-apps/panel/updata";
        var data = {
          id: this.allwidget.id,
          value: JSON.stringify(this.allwidget.value)
        }

        this.$axios.post(url, data).then((res) => {
          if (res.data.code == 0) {
            // this.$message.success(res.data.msg)
          } else {
            this.$message.warning(res.data.msg)
          }
        })
            .catch((err) => {
            }).finally(() => {

        })
      }

    },
    init() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000);
    }
  },
  created() {

  },
  mounted() {
    this.init()

    // this.getAppDictData()

  }
}
</script>

<style scoped lang="less">
#dragBoard {
  height: inherit;
  overflow: auto;
}

</style>
